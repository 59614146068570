import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const DELETE_SUB_USER = 'DELETE_SUB_USER'

export const service = async (subUserId) => {
  const url = BASE_API_URL + `/v1/jobtravia/user/deletesubuser/`+ subUserId
  const options = {
    ...getDefaultOptions(),
    method: 'PATCH',   
  }
  const response = await window.fetch(url, options)
  const subUser = await deserializeJsonResponse(response)
  throwExceptionErrors(subUser)
  return subUser
}

const mock = request => Promise.resolve({
  id: 1,
  data: [
    { name: 'Employer1', id: 1 },
    { name: 'Employer2', id: 2 },
    { name: 'Employer3', id: 3 },
    { name: 'Employer4', id: 4 },
    { name: 'Employer5', id: 5 }]
})

register(DELETE_SUB_USER, service, mock)
