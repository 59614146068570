
import { compose, withHandlers, withState } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { actions as asyncActions } from 'async-ops'
import { withRouter } from 'react-router'
import { initialize } from 'redux-form'
import Header from './component'
import { EXPIRE_SESSION_FOR_ADMIN, EXPIRE_SESSION } from './constants'
import { isEmpty, isNil } from 'lodash'
import { JOBTRAVIA_ADMIN_DOMAIN } from 'constants/commonConstants'

const CANDIDATE_USER = 1
const COMPANY_USER = 2
const COMPANY_SUB_USER = 4
const REGISTRATION_STATUS = 3
const PROFILE_MENU = 1
const SETTINGS_MENU = 2
const MY_SERVICES = 3
const RECOMMENDED_JOBS = 4
const APPLIED_JOBS = 5
const JOB_POST = 6
const MANAGE_USER = 7
const CANDIDATE_SEARCH = 8
const INVITE_FRIENDS = 9
const LOG_OUT = 99

const mapStateToProps = state => ({
  header: state.changeEmail.UserRole === 1 ? state.changeEmail : state.companyProfile
})

const mapDispatchToProps = dispatch => bindActionCreators({
  asyncOperationStart: asyncActions.asyncOperationStart,
  initialize
}, dispatch)

const handler = {
  onRegisterClick: props => () => {
    props.history.push('/register')
    props.setSidebarOpenClose(false)
  },
  onLoginClick: props => () => {
    props.history.push('/login')
    props.setSidebarOpenClose(false)
  },
  onAboutUsClick: props => () => {
    props.history.push('/aboutUs')
    props.setSidebarOpenClose(false)
  },
  onLogoClick: props => () => {
    if (window.location.href.indexOf('/termsandcondition') < 0) {
      if (sessionStorage.getItem('UserId') === undefined || sessionStorage.getItem('UserId') === null) {
        window.location.href = 'https://jobtravia.com'
      } else {
        if (props.header.UserRole === 1) { props.history.push('/welcome') } else { props.history.push('/companywelcome') }
      }
    }
  },
  onServicesClick: props => () => {
    props.history.push('/services')
    props.setSidebarOpenClose(false)
  },
  onContactUsClick: props => () => {
    props.history.push('/contact')
    props.setSidebarOpenClose(false)
  },
  openSidebar: props => () => {
    props.setSidebarOpenClose(true)
  },
  closeSidebar: props => () => {
    props.setSidebarOpenClose(false)
  },
  onProfileMenuClick: props => (event) => {
    let selectedValue = event.value
    if (selectedValue === SETTINGS_MENU && props.header.UserRole === CANDIDATE_USER) {
      props.history.push('/settings')
    } else if (selectedValue === SETTINGS_MENU && (props.header.UserRole === COMPANY_USER || props.header.UserRole === COMPANY_SUB_USER)) {
      props.history.push('/companysettings')
      window.location.reload(true)
    } else if (selectedValue === PROFILE_MENU && props.header.UserRole === CANDIDATE_USER) {
      props.history.push('/candidateProfile')
    } else if (selectedValue === PROFILE_MENU && props.header.UserRole === COMPANY_USER) {
      props.history.push('/companyProfile')
      window.location.reload(true)
    } else if (selectedValue === CANDIDATE_SEARCH && (props.header.CompanyStatus === REGISTRATION_STATUS || props.header.EmailId.indexOf(JOBTRAVIA_ADMIN_DOMAIN) !== -1)) {
      props.history.push('/candidatesearch')
    } else if (selectedValue === JOB_POST) {
      props.history.push('/jobs')
    } else if (selectedValue === MANAGE_USER && (props.header.UserRole === COMPANY_USER && (props.header.CompanyStatus === REGISTRATION_STATUS || props.header.EmailId.indexOf(JOBTRAVIA_ADMIN_DOMAIN) !== -1))) {
      props.history.push('/manageuser')
    } else if (selectedValue === LOG_OUT) {
      sessionStorage.removeItem('UserId')
      sessionStorage.removeItem('Access_Token')
      props.history.push('/login')
      document.cookie = EXPIRE_SESSION
      document.cookie = EXPIRE_SESSION_FOR_ADMIN
    } else if (selectedValue === MY_SERVICES && props.header.UserRole === CANDIDATE_USER) {
      props.history.push('/myservices')
    } else if (selectedValue === MY_SERVICES && props.header.UserRole === COMPANY_USER) {
      props.history.push('/companymyservices')
    } else if (selectedValue === RECOMMENDED_JOBS) {
      if (window.location.href.indexOf('/jobsearch') > 0) {
        window.location.reload(true)
      } else {
        props.history.push('/jobsearch')
      }
    } else if (selectedValue === APPLIED_JOBS && props.header.CandidateStatus === REGISTRATION_STATUS) {
      props.history.push('/appliedjobs')
    } else if (selectedValue === INVITE_FRIENDS && props.header.CandidateStatus === REGISTRATION_STATUS) {
      props.history.push('/invitefriends')
    }
  }
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withState('sidebarOpenClose', 'setSidebarOpenClose', false),
  withHandlers(handler)
)

export default enhance(Header)
