import { isNil, isEmpty } from 'lodash'
import { GET_IMPORTED_CANDIDATE_LIST } from '../services/getImportedCandidateList'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { convertDate,convertDateTime } from 'utils/dateUtils'
import { GET_IMPORTED_CANDIDATE_FOR_UPDATE } from '../../AddCandidates/services/getImportCandidateForUpdate'
import { ENABLE_DISABLE_USER } from '../services/enableDisableUser'
import { DELETE_SUB_USER } from '../services/deleteSubUser'
const CLEAR_IMPORTED_CANDIDATE_LIST = 'CLEAR_IMPORTED_CANDIDATE_LIST'
export const SORT_IMPORTED_CANDIDATE_LIST = 'SORT_IMPORTED_CANDIDATE_LIST'
export const SET_ERROR_MESSAGE_IMPORTED_CANDIDATE_LIST = 'SET_ERROR_MESSAGE_IMPORTED_CANDIDATE_LIST'


const initialError = {
  code: '',
  message: '',
  values: []
}
const initialItem = {
  value: 0,
  label: ''
}
const mapListItem = item => ({
  ...initialItem,
  value: item.id,
  label: item.name
})
const initialState = {
  loading: false,
  loadingAddEdit: false,
  error: initialError,
  sortOrder: {},
  TotalCandidateCount:0,
  list: [],  
  CandidateList: [],  
  MobileNumberList: [],
  EmailAddressList: [],
  isUserUpdate: false
}

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE_IMPORTED_CANDIDATE_LIST,
  message
})

export const clearImportedCandidateList = () => ({
  type: CLEAR_IMPORTED_CANDIDATE_LIST
})

export const sortImportedCandidateList = (colName, currentColOrder, dataType) => ({
  type: SORT_IMPORTED_CANDIDATE_LIST,
  colName,
  currentColOrder,
  dataType
})

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_IMPORTED_CANDIDATE_LIST: return clearImportedCandidateInfoList(state, action)
    case SORT_IMPORTED_CANDIDATE_LIST: return sortImportedCandidateListInfo(state, action)
    case SET_ERROR_MESSAGE_IMPORTED_CANDIDATE_LIST : return setError(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_IMPORTED_CANDIDATE_LIST)(action)) return getImportedCandidateList(state, action)
      if (isAsyncComplete(GET_IMPORTED_CANDIDATE_LIST)(action)) return getImportedCandidateListResult(state, action)
      if (isAsyncFailure(GET_IMPORTED_CANDIDATE_LIST)(action)) return getImportedCandidateListError(state, action)
    
      if (isAsyncOperation(ENABLE_DISABLE_USER)(action)) return updateUser(state, action)
      if (isAsyncComplete(ENABLE_DISABLE_USER)(action)) return updateUserResult(state, action)
      if (isAsyncFailure(ENABLE_DISABLE_USER)(action)) return updateUserError(state, action)

      if (isAsyncOperation(DELETE_SUB_USER)(action)) return deleteSubUser(state, action)
      if (isAsyncComplete(DELETE_SUB_USER)(action)) return deleteSubResult(state, action)
      if (isAsyncFailure(DELETE_SUB_USER)(action)) return deleteSubError(state, action)

      return state
    default:
      return state
  }
}

const sortImportedCandidate = (colName, colOrder, dataType) => (a, b) => {
  const aValue = isNil(a[colName]) ? '' : dataType !== 'number' ? a[colName].toLowerCase() : a[colName]
  const bValue = isNil(b[colName]) ? '' : dataType !== 'number' ? b[colName].toLowerCase() : b[colName]
  if (colOrder === 'desc') {
    if (aValue > bValue) return -1
    if (aValue < bValue) return 1
    return 0
  }
  if (aValue < bValue) return -1
  if (aValue > bValue) return 1
  return 0
}
export const sortImportedCandidateListInfo = (state, action) => {
  const currentSortOrder = action.currentColOrder === 'asc' ? 'desc' : 'asc'
  return {
    ...state,
    list: [...state.list].sort(sortImportedCandidate(action.colName, currentSortOrder, action.dataType)),
    sortOrder: { colName: action.colName, currentColOrder: currentSortOrder }
  }
}
const setError = (state, action) => ({
  ...state,
  errors: [action.message]
})
const getImportedCandidateList = (state, action) => ({
  ...state,
  loading: true
})

const clearImportedCandidateInfoList = (state, action) => ({
  ...initialState
})

const mapItem = item => ({
  ...initialState,  
  UserName: item.userName,
  CandidateTypeName: item.candidateTypeName,  
  UserLogin: item.userLogin,  
  MobileNumber: item.mobileNumber,
  LastModifiedDate: convertDateTime(item.lastModifiedDate),  
  ImportedCandidateUserId: item.userId,
  Narration: item.narration,
  EnableDisable: item.enableDisable  
})

const getImportedCandidateListResult = (state, action) => {
  const ImportedCandidate = action.response || {}
  const data = ImportedCandidate.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: [data[1] + ' Records Found.'],
      list: data[0].map(mapItem),
      TotalCandidateCount: data[1],
      isUserUpdate: false
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: [data.errors],
      list: [],
      isUserUpdate: false
    }
  }
}

const getImportedCandidateListError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const updateUser = (state, action) => ({
  ...state,
  loading: true
})

const updateUserResult = (state, action) => {
  const applyJob = action.response || {}
  const data = applyJob.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      isUserUpdate: true
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: initialError,
      isUserUpdate: false
    }
  }
}
const updateUserError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const deleteSubUser = (state, action) => ({
  ...state,
  loading: true
})

const deleteSubResult = (state, action) => {
  const applyJob = action.response || {}
  const data = applyJob.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      isUserUpdate: true
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: initialError,
      isUserUpdate: false
    }
  }
}
const deleteSubError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})