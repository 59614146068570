import { GET_COUPON } from './../../MyServices/services/getCoupon'
import { REGISTER_COMPANY_PAYMENT } from './../services/makePaymentCompany'
import { GET_ENCRYPTED_DATA_COMPANY } from '../services/getEncryptedDataCompany'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE'
export const CLEAR_COMPANY_MYSERVICE_INFO_DATA = 'CLEAR_COMPANY_MYSERVICE_INFO_DATA'

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE,
  message
})

export const clearErrorMessage = (message) => ({
  type: CLEAR_ERROR_MESSAGE
})

export const clearMyServiceCompanyInfoData = () => ({
  type: CLEAR_COMPANY_MYSERVICE_INFO_DATA
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  IsCouponValidated: false,
  IsUserRegistered: false,
  IsTransactionCancelled: false,
  encRequest: '',
  accessCode: '',
  redirectUrl: '',
  ResetValues: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ERROR_MESSAGE: return setError(state, action)
    case CLEAR_ERROR_MESSAGE: return clearError(state, action)
    case CLEAR_COMPANY_MYSERVICE_INFO_DATA: return clearMyServiceCompanyInfo(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_COUPON)(action)) return getCoupon(state, action)
      if (isAsyncComplete(GET_COUPON)(action)) return getCouponResult(state, action)
      if (isAsyncFailure(GET_COUPON)(action)) return getCouponError(state, action)

      if (isAsyncOperation(REGISTER_COMPANY_PAYMENT)(action)) return registerCompany(state, action)
      if (isAsyncComplete(REGISTER_COMPANY_PAYMENT)(action)) return registerCompanyResult(state, action)
      if (isAsyncFailure(REGISTER_COMPANY_PAYMENT)(action)) return registerCompanyError(state, action)

      if (isAsyncOperation(GET_ENCRYPTED_DATA_COMPANY)(action)) return encryptedData(state, action)
      if (isAsyncComplete(GET_ENCRYPTED_DATA_COMPANY)(action)) return encryptedDataResult(state, action)
      if (isAsyncFailure(GET_ENCRYPTED_DATA_COMPANY)(action)) return encryptedDataError(state, action)
      return state
    default: return state
  }
}

const clearMyServiceCompanyInfo = (state, action) => ({
  ...initialState
})

const setError = (state, action) => ({
  ...state,
  errors: [action.message]
})

const clearError = (state, action) => ({
  ...state,
  errors: [],
  IsCouponValidated: false,
  IsUserRegistered: false
})

const getCoupon = (state, action) => ({
  ...initialState,
  loading: true
})

const getCouponResult = (state, action) => {
  const coupon = action.response || {}
  const data = coupon.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      IsCouponValidated: true,
      CouponDiscount: data[0].couponDiscount,
      CouponId: data[0].couponId,
      ValidDays: data[0].validDays,
      errors: ['Coupon Applied.'],
      UserId: data[0].userId
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: coupon.errors,
      IsCouponValidated: false,
      ResetValues: true,
      CouponDiscount: 0
    }
  }
}

const getCouponError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const registerCompany = (state, action) => ({
  ...initialState,
  loading: true
})

const registerCompanyResult = (state, action) => {
  const registerCandidate = action.response || {}
  const data = registerCandidate.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      IsUserRegistered: true,
      IsTransactionCancelled: false
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: registerCandidate.errors,
      IsUserRegistered: false,
      IsTransactionCancelled: true
    }
  }
}

const registerCompanyError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const encryptedData = (state, action) => ({
  ...initialState,
  loading: true
})

const encryptedDataResult = (state, action) => {
  const encryptedData = action.response || {}
  const data = encryptedData.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      encRequest: data[0],
      accessCode: data[1],
      redirectUrl: data[2]
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: registerCompany.errors,
      encRequest: '',
      accessCode: '',
      redirectUrl: ''
    }
  }
}

const encryptedDataError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
