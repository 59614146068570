
import { GET_FUNCTIONAL_AREA } from './../services/getFunctionalArea'
import { GET_INDUSTRY } from './../services/getIndustry'
import { GET_LOCATION } from './../services/getLocation'
import { GET_ROLE } from './../services/getRole'
import { GET_PREFERED_ROLE } from './../services/getPreferedRole'
import { GET_KEYSKILLS } from './../services/getKeySkills'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  IsOtpGenerated: false,
  IsUserRegisterd: false,
  UserId: 0,
  FunctionalAreaList: [],
  IndustryList: [],
  LocationList: [],
  RoleList: [],
  PreferedRoleList: [],
  KeySkillsList: []
}

const initialOption = {
  label: '',
  options: []
}

export const initialCertificationList = {
  CertificationName: '',
  Description: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_FUNCTIONAL_AREA)(action)) return getFunctionalArea(state, action)
      if (isAsyncComplete(GET_FUNCTIONAL_AREA)(action)) return getFunctionalAreaResult(state, action)
      if (isAsyncFailure(GET_FUNCTIONAL_AREA)(action)) return getFunctionalAreaError(state, action)

      if (isAsyncOperation(GET_INDUSTRY)(action)) return getIndustry(state, action)
      if (isAsyncComplete(GET_INDUSTRY)(action)) return getIndustryResult(state, action)
      if (isAsyncFailure(GET_INDUSTRY)(action)) return getIndustryError(state, action)

      if (isAsyncOperation(GET_LOCATION)(action)) return getLocation(state, action)
      if (isAsyncComplete(GET_LOCATION)(action)) return getLocationResult(state, action)
      if (isAsyncFailure(GET_LOCATION)(action)) return getLocationError(state, action)

      if (isAsyncOperation(GET_ROLE)(action)) return getRole(state, action)
      if (isAsyncComplete(GET_ROLE)(action)) return getRoleResult(state, action)
      if (isAsyncFailure(GET_ROLE)(action)) return getRoleError(state, action)

      if (isAsyncOperation(GET_PREFERED_ROLE)(action)) return getPreferedRole(state, action)
      if (isAsyncComplete(GET_PREFERED_ROLE)(action)) return getPreferedRoleResult(state, action)
      if (isAsyncFailure(GET_PREFERED_ROLE)(action)) return getPreferedRoleError(state, action)

      if (isAsyncOperation(GET_KEYSKILLS)(action)) return getKeySkills(state, action)
      if (isAsyncComplete(GET_KEYSKILLS)(action)) return getKeySkillsResult(state, action)
      if (isAsyncFailure(GET_KEYSKILLS)(action)) return getKeySkillsError(state, action)
      return state
    default: return state
  }
}

const getFunctionalArea = (state, action) => ({
  ...state,
  loading: true
})

const mapItem = item => ({
  value: item.id,
  label: item.name
})

const getFunctionalAreaResult = (state, action) => {
  const functionalArea = action.response || {}
  const data = functionalArea.data.length > 0 ? functionalArea.data : []
  return {
    ...state,
    loading: false,
    errors: initialError,
    FunctionalAreaList: data[0].map(mapItem)
  }
}

const getFunctionalAreaError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const getIndustry = (state, action) => ({
  ...state,
  loading: true
})

const getIndustryResult = (state, action) => {
  const industry = action.response || {}
  const data = industry.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      IndustryList: data[0].map(mapItem)
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: initialError,
      IndustryList: []
    }
  }
}

const getIndustryError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const getLocation = (state, action) => ({
  ...state,
  loading: true
})

const mapLocationItem = item => ({
  ...initialOption,
  label: item.stateName,
  options: item.locationList.map(mapItem)
})

const getLocationResult = (state, action) => {
  const location = action.response || {}
  const data = location.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      LocationList: data.map(mapLocationItem)
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: location.errors,
      LocationList: []
    }
  }
}

const getLocationError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const getRole = (state, action) => ({
  ...state,
  loading: true
})

const mapRoleItem = item => ({
  ...initialOption,
  label: item.functionalAreaSubName,
  options: item.roleList.map(mapItem)
})

const getRoleResult = (state, action) => {
  const role = action.response || {}
  const data = role.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      RoleList: data.map(mapRoleItem)
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: role.errors,
      RoleList: []
    }
  }
}

const getRoleError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const getPreferedRole = (state, action) => ({
  ...state,
  loading: true
})

const getPreferedRoleResult = (state, action) => {
  const preferedRole = action.response || {}
  const data = preferedRole.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      PreferedRoleList: data.map(mapRoleItem)
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: preferedRole.errors,
      PreferedRoleList: []
    }
  }
}

const getPreferedRoleError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const getKeySkills = (state, action) => ({
  ...state,
  loading: true
})

const getKeySkillsResult = (state, action) => {
  const preferedRole = action.response || {}
  const data = preferedRole.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      KeySkillsList: data[0].map(mapItem)
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: preferedRole.errors,
      KeySkillsList: []
    }
  }
}

const getKeySkillsError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
