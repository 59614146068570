import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const GET_FEATURE_SERVICE = 'GET_FEATURE_SERVICE'

const url = BASE_API_URL + '/feature'
export const service = async () => {
  const options = {
    ...getDefaultOptions(),
    method: 'GET'
  }
  const response = await window.fetch(url, options)
  const feature = await deserializeJsonResponse(response)
  throwExceptionErrors(feature)
  return feature
}

const mockFeature = () => {
  const data = []
  for (let i = 0; i < 10; i++) {
    data.push({
      featureId: i,
      featureName: 'Feature ' + i
    })
  }
  return data
}

const mock = request => Promise.resolve(mockFeature())

register(GET_FEATURE_SERVICE, service, mock)
