import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../../utils/fetch'
import { BASE_API_URL } from '../../../constants/url'

export const SEND_INQUIRY = 'SEND_INQUIRY'

export const service = async (data) => {
  const url = BASE_API_URL + `/v1/jobtravia/inquiry`
  const options = {
    ...getDefaultOptions(),
    method: 'POST',
    body: JSON.stringify(data)
  }
  const response = await window.fetch(url, options)
  const sendInquiry = await deserializeJsonResponse(response)
  throwExceptionErrors(sendInquiry)
  return sendInquiry
}

const mock = request => Promise.resolve({
  id: 1,
  data: []
})

register(SEND_INQUIRY, service, mock)
