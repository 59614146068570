import React from 'react'
import './index.scss'
import { CONTACT_EMAIL_ADDRESS, CONTACT_ADDRESS,LINKEDIN_URL, FACEBOOK_URL, INSTAGRAM_URL, YOUTUBE_URL, TWITTER_URL,TERMS_AND_CONDITION, PRIVACY_POLICY } from './constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faLinkedinIn, faInstagram, faFacebookF, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons'
import {Link} from 'bizsense-components'
const MobileFooter = props =>
  <div id='mobile-footer-container-id' className='mobile-footer-container'>
    <div id='mobile-footer-component-id' className='mobile-footer-component'>
      <div id='mobile-address-id' className='mobile-address'>
        <FontAwesomeIcon icon={faMapMarkerAlt} className='fa-icon' />{CONTACT_ADDRESS}
      </div>
      <div id='mobile-location-icon-id' className='mobile-location-icon'>
        <FontAwesomeIcon icon={faEnvelope} className='fa-icon' />{ CONTACT_EMAIL_ADDRESS }
      </div>
      <div id='privacy-policy-terms-and-condition-section-id' className='privacy-policy-terms-and-condition-section'>
        <Link id='terms-and-condition-link-id' className='link-color' onClick={props.onTermsAndConditionClick}>{TERMS_AND_CONDITION}</Link> 
        <Link id='privacy-policy-link-id' className='link-color' onClick={props.onPrivacyPolicyClick}>{PRIVACY_POLICY}</Link>  
      </div>
      <div id='social-media-section-id' className='social-media-section'>
        <div id='follow-us-id' className='follow-us'>
        Follow us on:
        </div>
        <div>
          <a id='linkedin-icon-id' className='link-color' href={LINKEDIN_URL} target='_blank' >
            <FontAwesomeIcon icon={faLinkedinIn} className='icons' />
          </a>
          <a id='facebook-icon-id' className='link-color' href={FACEBOOK_URL} target='_blank' >
          <FontAwesomeIcon icon={faFacebookF} className='icons' />
          </a>
          <a id='instagram-icon-id' className='link-color' href={INSTAGRAM_URL} target='_blank' >
          <FontAwesomeIcon icon={faInstagram} className='icons' />
          </a>
          <a id='youtube-icon-id' className='link-color' href={YOUTUBE_URL} target='_blank' >
          <FontAwesomeIcon icon={faYoutube} className='icons' />
          </a>
          <a id='twitter-icon-id' className='link-color' href={TWITTER_URL} target='_blank' >
          <FontAwesomeIcon icon={faTwitter} className='icons' />
          </a>
        </div>
      </div>
    </div>
  </div>

export default MobileFooter
