import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const GET_JOB_APPLIED_LIST = 'GET_JOB_APPLIED_LIST'

export const service = async (pagenumber,jobId) => {
  const url = BASE_API_URL + `/v1/jobtravia/jobpost/getjobappliedlist/` + pagenumber +'/' + jobId
  const options = {
    ...getDefaultOptions(),
    method: 'GET'
  }
  const response = await window.fetch(url, options)
  const jobpost = await deserializeJsonResponse(response)
  throwExceptionErrors(jobpost)
  return jobpost
}

const mock = request => Promise.resolve({
  id: 1,
  data: [[
    { jobCode: 1, emailId: 'test@gmail.com', mobileNumber: '8888888888' },
    { jobCode: 2, emailId: 'test2@gmail.com', mobileNumber: '9999999999' },
    { jobCode: 3, emailId: 'test3@gmail.com', mobileNumber: '7777777777' },]]
})

register(GET_JOB_APPLIED_LIST, service, mock)
