const SET_FUNCTION_MODE = 'SET_FUNCTION_MODE'

export const setFunctionMode = (value) => ({
  type: SET_FUNCTION_MODE,
  value
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  FunctionMode: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_FUNCTION_MODE: return setFunctionModeInfo(state, action)
    default: return state
  }
}

const setFunctionModeInfo = (state, action) => ({
  ...state,
  FunctionMode: action.value
})
