import { isNil } from 'lodash'
import { GET_JOB_APPLIED_LIST } from '../services/getJobAppliedList'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { convertDate } from 'utils/dateUtils'

export const SORT_JOB_APPLICATIONS = 'SORT_JOB_APPLICATIONS'

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  loadingAddEdit: false,
  error: initialError,
  sortOrder: {}
}

export const sortJobApplicationList = (colName, currentColOrder, dataType) => ({
  type: SORT_JOB_APPLICATIONS,
  colName,
  currentColOrder,
  dataType
})

export default function (state = initialState, action) {
  switch (action.type) {
    case SORT_JOB_APPLICATIONS: return sortJobAppliedInfo(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_JOB_APPLIED_LIST)(action)) return getJobAppliedList(state, action)
      if (isAsyncComplete(GET_JOB_APPLIED_LIST)(action)) return getJobAppliedListResult(state, action)
      if (isAsyncFailure(GET_JOB_APPLIED_LIST)(action)) return getJobAppliedListError(state, action)
      return state
    default:
      return state
  }
}

const sortjobApplications = (colName, colOrder, dataType) => (a, b) => {
  const aValue = isNil(a[colName]) ? '' : dataType !== 'number' ? a[colName].toLowerCase() : a[colName]
  const bValue = isNil(b[colName]) ? '' : dataType !== 'number' ? b[colName].toLowerCase() : b[colName]
  if (colOrder === 'desc') {
    if (aValue > bValue) return -1
    if (aValue < bValue) return 1
    return 0
  }
  if (aValue < bValue) return -1
  if (aValue > bValue) return 1
  return 0
}

export const sortJobAppliedInfo = (state, action) => {
  const currentSortOrder = action.currentColOrder === 'asc' ? 'desc' : 'asc'
  return {
    ...state,
    list: [...state.list].sort(sortjobApplications(action.colName, currentSortOrder, action.dataType)),
    sortOrder: { colName: action.colName, currentColOrder: currentSortOrder }
  }
}

const getJobAppliedList = (state, action) => ({
  ...state,
  loading: true
})

const mapItem = item => ({
  ...initialState,
  JobId: item.jobId,
  CandidateId :item.candidateId,
  JobCode :item.jobCode,
  EmailId: item.emailId,
  MobileNumber: item.mobileNumber,
  LastName :item.lastName,
  FirstName:item.firstName + ' '+item.lastName ,
  LastModifiedDate: convertDate(item.lastModifiedDate, 'DD-MM-YYYY'),
  ResumeSize: item.resumeSize,
  ResumeUrl: item.resumeUrl,
  ResumeBase64String: item.resumeBase64String,
  CandidateTypeName: item.candidateTypeName,
  LocationName: item.locationName,
  Designation : item.designation
})

const getJobAppliedListResult = (state, action) => {
  const jobApplications = action.response || {}
  const data = jobApplications.data
  if (data !== null) {
    return {
      ...state,
      loading: false,
      errors: [data[1] + ' Applications Found.'],
      list: data[0].map(mapItem),
      totalJobCount: data[1]
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: [data.errors],
      list: []
    }
  }
}

const getJobAppliedListError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
