import { CHANGE_USER_PASSWORD } from '../services/changePassword'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'

const CLEAR_CHANGE_PASSWORD = 'CLEAR_CHANGE_PASSWORD'

export const clearChangePassword = () => ({
  type: CLEAR_CHANGE_PASSWORD
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  CurrentPassword: '',
  NewPassword: '',
  UserId: 0,
  IsPasswordChanged: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_CHANGE_PASSWORD: return clearChangePasswordForUser(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(CHANGE_USER_PASSWORD)(action)) return changePassword(state, action)
      if (isAsyncComplete(CHANGE_USER_PASSWORD)(action)) return changePasswordResult(state, action)
      if (isAsyncFailure(CHANGE_USER_PASSWORD)(action)) return changePasswordError(state, action)
      return state
    default: return state
  }
}

const clearChangePasswordForUser = (state, action) => ({
  ...initialState
})

const changePassword = (state, action) => ({
  ...initialState,
  loading: true
})

const changePasswordResult = (state, action) => {
  const changePassword = action.response || {}
  const data = changePassword.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      IsPasswordChanged: true,
      errors: ['Password has been changed Successfully.']
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: changePassword.errors,
      IsPasswordChanged: false
    }
  }
}

const changePasswordError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
