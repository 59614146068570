import React from 'react'
import LoadingIcon from '../LoadingIcon'
import './index.scss'

const Loader = ({ show = false }) => !show ? null
  : <div className='bizsense-ui-loader-overlay' id='loader-id'>
    <LoadingIcon />
  </div>

export default Loader
