import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const SEND_USER_OTP = 'SEND_USER_OTP'

export const service = async (data) => {
  const url = BASE_API_URL + `/v1/jobtravia/notification`
  const options = {
    ...getDefaultOptions(),
    method: 'POST',
    body: JSON.stringify(data)
  }
  const response = await window.fetch(url, options)
  const otpSent = await deserializeJsonResponse(response)
  throwExceptionErrors(otpSent)
  return otpSent
}

const mock = request => Promise.resolve({
  id: 1,
  data: [true]
})

register(SEND_USER_OTP, service, mock)
