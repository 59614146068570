import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const GET_IMPORTED_CANDIDATE_FOR_UPDATE = 'GET_IMPORTED_CANDIDATE_FOR_UPDATE'

export const service = async (importedCandidateUserId, baseUrl = BASE_API_URL) => {
  const url = baseUrl + `/v1/jobtravia/candidateprofile/getcandidatebyuserid/` + importedCandidateUserId
  const options = {
    ...getDefaultOptions(),
    method: 'GET'
  }
  const response = await window.fetch(url, options)
  const candidate = await deserializeJsonResponse(response)
  throwExceptionErrors(candidate)
  return candidate
}

const mock = request => Promise.resolve({
  id: 1,
  data: [{
    UserId: 1,
    CurrentEmailId: 'test@gmail.com',
    MobileNumber: '9087654321'
  }]
})

register(GET_IMPORTED_CANDIDATE_FOR_UPDATE, service, mock)
