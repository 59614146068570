import { USER_LOGIN } from '../services/userLogin'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'

const CLEAR_LOGIN = 'CLEAR_LOGIN'

export const clearLogin = () => ({
  type: CLEAR_LOGIN
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  isUserExist: false,
  userId: 0,
  isUserLoggedOut: false,
  bearer: '',
  userRole: 0
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_LOGIN: return clearLoginInfo(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(USER_LOGIN)(action)) return getUserLogin(state, action)
      if (isAsyncComplete(USER_LOGIN)(action)) return getUserLoginResult(state, action)
      if (isAsyncFailure(USER_LOGIN)(action)) return getUserLoginError(state, action)
      return state
    default: return state
  }
}

const clearLoginInfo = (state, action) => ({
  ...initialState
})

const getUserLogin = (state, action) => ({
  ...initialState,
  loading: true
})

const getUserLoginResult = (state, action) => {
  const login = action.response || {}
  const data = login.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      isUserExist: true,
      userId: data[0].userId,
      userRole: data[0].userRole,
      bearer: data[1]
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: login.errors,
      isUserExist: false
    }
  }
}

const getUserLoginError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
