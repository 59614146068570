import { isNil } from 'lodash'
export const getDefaultOptions = () => ({
  headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': !isNil(sessionStorage.getItem('Access_Token')) ? 'Bearer ' + sessionStorage.getItem('Access_Token') : '' },
  credentials: 'same-origin'
})

export const throwExceptionErrors = response => {
  if (!response || !response.code) return
  const error = new Error(response.code)
  error.code = response.code || ''
  error.values = response.values
  throw error
}

export const deserializeJsonResponse = async (response, defaultReturn = []) => {
  const text = await response.text()
  return text.length ? JSON.parse(text) : defaultReturn
}
