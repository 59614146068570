import { UPDATE_RECRUITMENT_DETAILS } from '../services/updateRecruitmentDetails'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE'

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE,
  message
})

export const clearErrorMessage = (message) => ({
  type: CLEAR_ERROR_MESSAGE
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  userId: 0,
  companyId: 0
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ERROR_MESSAGE: return setError(state, action)
    case CLEAR_ERROR_MESSAGE: return clearError(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(UPDATE_RECRUITMENT_DETAILS)(action)) return updateRecruitmentDetails(state, action)
      if (isAsyncComplete(UPDATE_RECRUITMENT_DETAILS)(action)) return updateRecruitmentDetailsResult(state, action)
      if (isAsyncFailure(UPDATE_RECRUITMENT_DETAILS)(action)) return updateRecruitmentDetailsError(state, action)
      return state
    default: return state
  }
}

const setError = (state, action) => ({
  ...state,
  errors: [action.message]
})

const clearError = (state, action) => ({
  ...state,
  errors: [],
  IsRecruitmentDetailsUpdated: false
})

const updateRecruitmentDetails = (state, action) => ({
  ...initialState,
  loading: true
})

const updateRecruitmentDetailsResult = (state, action) => {
  const updateRecruitmentDetails = action.response || {}
  const data = updateRecruitmentDetails.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      IsRecruitmentDetailsUpdated: true
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: updateRecruitmentDetails.errors,
      IsRecruitmentDetailsUpdated: false
    }
  }
}

const updateRecruitmentDetailsError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
