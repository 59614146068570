export const CONTACT_EMAIL_ADDRESS = 'contact@jobtravia.com'
export const CONTACT_ADDRESS = 'JobTravia Pvt. Ltd., 301, Prayag Enclave, WHC Road, Shankar Nagar, Nagpur- 440010 India.'
export const CONTACT_NUMBER = '+91-712-22-94-2222, +91-9372-391-902, +91-956-107-9687'
export const LINKEDIN_URL = 'https://www.linkedin.com/company/jobtravia-pvt-ltd/about/'
export const FACEBOOK_URL = 'https://www.facebook.com/recruitersJobTravia/' 
export const INSTAGRAM_URL = 'https://www.instagram.com/jobtravia/?hl=en'
export const YOUTUBE_URL = 'https://www.youtube.com/channel/UCF0JDmA8st44u-4odUvucaw'
export const TWITTER_URL = 'https://twitter.com/JobTravia'
export const TERMS_AND_CONDITION = 'Terms & Conditions'
export const PRIVACY_POLICY = 'Privacy Policy'
