import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const GET_LOCATION = 'GET_LOCATION'

export const service = async (isAnyWhereOptionIsDisplayInLocation) => {
  const url = BASE_API_URL + `/v1/jobtravia/location/getlocationlist/` + isAnyWhereOptionIsDisplayInLocation
  const options = {
    ...getDefaultOptions(),
    method: 'GET'
  }
  const response = await window.fetch(url, options)
  const location = await deserializeJsonResponse(response)
  throwExceptionErrors(location)
  return location
}

const mock = request => Promise.resolve({
  id: 1,
  data: [
    { name: 'Location1', id: 1 },
    { name: 'Location2', id: 2 },
    { name: 'Location3', id: 3 },
    { name: 'Location4', id: 4 },
    { name: 'Location5', id: 5 }]
})

register(GET_LOCATION, service, mock)
