import { FORGOTPASSWORD } from '../services/forgotPassword'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'
const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  isUserExist: false,
  userId: 0,
  isUserLoggedOut: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(FORGOTPASSWORD)(action)) return forgetPassword(state, action)
      if (isAsyncComplete(FORGOTPASSWORD)(action)) return forgetPasswordResult(state, action)
      if (isAsyncFailure(FORGOTPASSWORD)(action)) return forgetPasswordError(state, action)
      return state
    default: return state
  }
}

const forgetPassword = (state, action) => ({
  ...initialState,
  loading: true
})

const forgetPasswordResult = (state, action) => {
  const forgetPassword = action.response || {}
  const data = forgetPassword.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: ['Email has been sent to reset your password.'],
      isUserExist: true
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: forgetPassword.errors,
      isUserExist: false
    }
  }
}

const forgetPasswordError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
