import { SEND_INQUIRY } from '../services/sendInquiry'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'

const CLEAR_SEND_INQUIRY = 'CLEAR_SEND_INQUIRY'

const initialError = {
  code: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  FirstName: '',
  EmailID: '',
  MobileNumber: '',
  IsInquirySend: false
}

export const clearSendInquiry = () => ({
  type: CLEAR_SEND_INQUIRY
})

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_SEND_INQUIRY: return clearSendInquiryInfo(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(SEND_INQUIRY)(action)) return sendInquiry(state, action)
      if (isAsyncComplete(SEND_INQUIRY)(action)) return sendInquiryResult(state, action)
      if (isAsyncFailure(SEND_INQUIRY)(action)) return sendInquiryError(state, action)
      return state
    default: return state
  }
}

const clearSendInquiryInfo = (state, action) => ({
  ...initialState
})

const sendInquiry = (state, action) => ({
  ...state,
  loading: true,
  IsInquirySend: false
})

const sendInquiryResult = (state, action) => {
  const register = action.response || {}
  const data = register.data
  if (data !== null) {
    return {
      ...initialState,
      IsInquirySend: true
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: register.errors,
      IsInquirySend: false
    }
  }
}

const sendInquiryError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
