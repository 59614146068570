import React from 'react'
import { basicInfoFields, otpField } from './fields'
import { FormField, Button, Link, FormFieldGroup } from 'bizsense-components'
import Loader from '../../Loader'
import './../../styles/index.scss'
import './index.scss'
import { Form } from 'reactstrap'
import DecoratedField from '../../DecoratedField'
import Media from 'react-media'
import { OTP_BUTTON_TOOLTIP } from 'constants/commonConstants'
import { PAGE_TITLE, AGREEMENT, LOGIN_PAGE_LINK, TERMS_AND_CONDITION_LABEL } from './constants'

const getType = (props, field) => {
  const isPassword = field.name === 'UserPassword'
  field.type = isPassword ? props.show ? 'text' : 'password' : field.type
  if (isPassword) {
    field.onShowHideClick = props.togglePasswordModal
  }
  return field
}

const SectionField = props => (field, i) =>
  <DecoratedField
    key={i + field.name}
    {...field}
    {...getType(props, field)}
    options={props[field.optionList] || field.options}
    InputComponent={FormField}
  />

const mapSectionField = props => (field, i) =>
  <FormFieldGroup theme={'custom'} key={i}>
    {field.fields.map(SectionField(props))}
  </FormFieldGroup>

const PageTitle = props =>
  <div className='page-title' id='page-title-id'>
    {PAGE_TITLE}
  </div>

const GenerateOtp = props =>
  <div className='otp-section' id='otp-section-id'>
    <Button id ='generate-otp-id' className='round-button' bsStyle='custom' blockXs onClick={props.onGenerateOTPClick} tooltipMessage={OTP_BUTTON_TOOLTIP}>
      {props.registerCompany && props.registerCompany.IsOtpGenerated ? 'Resend OTP' : 'Generate OTP'}
    </Button>
    <div className='otp-section-input-field' id='otp-section-input-field-id'>
      {otpField.map(mapSectionField(props))}
    </div>
  </div>

const TermsAndCondition = props =>
  <div className='terms-and-condition' id='terms-and-condition'>
    {AGREEMENT}<Link onClick={props.onTermsAndConditionClick}>{TERMS_AND_CONDITION_LABEL}</Link>
  </div>

const RegisterButton = props =>
  <div className='register-button-section' id='register-button-section-id'>
    <Button.Toolbar>
      <Button id='register-click-id' className='round-button' bsStyle='custom' blockXs onClick={props.onRegisterClick} disable>
        Register
      </Button>
    </Button.Toolbar>
  </div>

const RegisterCompany = props => (
  <div className='bs-container register-user-form' id='bs-container register-user-form-id'>
    <div className='bs-formwrapper' id='bs-formwrapper-id'>
      <PageTitle {...props} />      
      <div className='page-content' id='page-content-id'>
        <div className='candidate-form' id='candidate-form-id'>
          <Form onSubmit={props.handleSubmit} className='register-form' id='register-form-id'>
            {basicInfoFields.map(mapSectionField(props))}
            <GenerateOtp {...props} />
            {
              props.errors.length > 0 &&
              <div className='message' id='message-id'>
                { props.errors[0] }
              </div>
            }
            <TermsAndCondition {...props} />
            <RegisterButton {...props} />
          </Form>
          <div id='login-link-id' className='login-link'>
            <Link id='login-click-id' onClick={props.onLoginClick}>
              {LOGIN_PAGE_LINK}
            </Link>
          </div>
        </div>        
      </div>
    </div>
    <Loader show={props.loading} />
  </div>
)
export default RegisterCompany
