import React from 'react'
import './index.scss'
import Media from 'react-media'
import MobileHeader from './MobileHeader'
import DesktopHeader from './DesktopHeader'

const Header = props =>
  <Media query='(min-width:991px)'>
    {matches =>
      matches ? <DesktopHeader {...props} /> : <MobileHeader {...props} />
    }
  </Media>
export default Header
