import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import RootRouter from './RootRouter'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import store from './store'
import 'images/Loader.gif'
import './service'
import './sagas'

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <RootRouter />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
