const UPLOAD_RESUME = 'UPLOAD_RESUME'
const CLEAR_RESUME_DETAIL = 'CLEAR_RESUME_DETAIL'

export const updateResumeState = (values = {}, documentType) => ({
  type: UPLOAD_RESUME,
  uploadResumeState: values,
  documentType
})

export const clearResumeDetails = (values = {}, documentType) => ({
  type: CLEAR_RESUME_DETAIL
})

export const initialPreviousExperienceList = {
  CompanyName: '',
  Designation: '',
  DurationFrom: 0,
  DurationTo: 0
}

const initialState = {
  src: '',
  resumeType: '',
  resumeUrl: '',
  resumeSize: 0,
  resumeBase64String: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case UPLOAD_RESUME: return updateResumeStateInfo(state, action)
    case CLEAR_RESUME_DETAIL: return clearResumeState(state, action)
    default:
      return state
  }
}

const updateResumeStateInfo = (state, action) => {
  return {
    ...state,
    ...action.uploadResumeState
  }
}

const clearResumeState = (state, action) => {
  return {
    ...initialState
  }
}
