export const EXPIRE_SESSION = 'UserId=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
export const EXPIRE_SESSION_FOR_ADMIN = 'JobTraviaAdmin=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
export const PROFILE_MENU_LIST = [
  { value: 1, label: 'Profile' },
  { value: 2, label: 'Settings' },
  { value: 3, label: 'My Services' },
  { value: 4, label: 'Recommended Jobs' },
  { value: 5, label: 'Applied Jobs' },
  { value: 9, label: 'Invite Friends' },
  { value: 99, label: 'Logout' }
]
export const COMPANY_MENU_LIST = [
  { value: 1, label: 'Profile' },
  { value: 2, label: 'Settings' },
  { value: 3, label: 'My Services' },
  { value: 6, label: 'Job Post' },
  { value: 7, label: 'Manage User' },
  { value: 8, label: 'Candidate Search' },  
  { value: 99, label: 'Logout' }
]
