const SET_PANEL_OPEN_CLOSE = 'SET_PANEL_OPEN_CLOSE'

export const setPanelOpenClose = (values = {}) => ({
  type: SET_PANEL_OPEN_CLOSE,
  setValues: values
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  openCloseChangePassword: true,
  openCloseChangeEmail: true,
  openCloseChangeContactDetails: true
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PANEL_OPEN_CLOSE: return setPanelOpenCloseInfo(state, action)
    default: return state
  }
}

const setPanelOpenCloseInfo = (state, action) => {
  return {
    ...state,
    ...action.setValues
  }
}
