import { isNil, isEmpty } from 'lodash'
import { GET_ACTIVE_COMPANY_LIST } from '../services/getActiveCompanyList'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { convertDate } from 'utils/dateUtils'


const CLEAR_ACTIVE_COMPANY_LIST = 'CLEAR_ACTIVE_COMPANY_LIST'
export const SORT_ACTIVE_COMPANY_LIST = 'SORT_ACTIVE_COMPANY_LIST'
export const SET_ERROR_MESSAGE_ACTIVE_COMPANY_LIST = 'SET_ERROR_MESSAGE_ACTIVE_COMPANY_LIST'

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  loadingAddEdit: false,
  error: initialError,
  sortOrder: {},
  TotalCompanyCount:0,
  list:[]
}
export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE_ACTIVE_COMPANY_LIST,
  message
})

export const clearActiveCompanyList = () => ({
  type: CLEAR_ACTIVE_COMPANY_LIST
})

export const sortActiveCompanyList = (colName, currentColOrder, dataType) => ({
  type: SORT_ACTIVE_COMPANY_LIST,
  colName,
  currentColOrder,
  dataType
})

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_ACTIVE_COMPANY_LIST: return clearActiveCompanyInfoList(state, action)
    case SORT_ACTIVE_COMPANY_LIST: return sortActiveCompanyListInfo(state, action)
    case SET_ERROR_MESSAGE_ACTIVE_COMPANY_LIST : return setError(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_ACTIVE_COMPANY_LIST)(action)) return getActiveCompanyList(state, action)
      if (isAsyncComplete(GET_ACTIVE_COMPANY_LIST)(action)) return getActiveCompanyListResult(state, action)
      if (isAsyncFailure(GET_ACTIVE_COMPANY_LIST)(action)) return getActiveCompanyListError(state, action)
      return state
    default:
      return state
  }
}

const sortActiveCompany = (colName, colOrder, dataType) => (a, b) => {
  const aValue = isNil(a[colName]) ? '' : dataType !== 'number' ? a[colName].toLowerCase() : a[colName]
  const bValue = isNil(b[colName]) ? '' : dataType !== 'number' ? b[colName].toLowerCase() : b[colName]
  if (colOrder === 'desc') {
    if (aValue > bValue) return -1
    if (aValue < bValue) return 1
    return 0
  }
  if (aValue < bValue) return -1
  if (aValue > bValue) return 1
  return 0
}
export const sortActiveCompanyListInfo = (state, action) => {
  const currentSortOrder = action.currentColOrder === 'asc' ? 'desc' : 'asc'
  return {
    ...state,
    list: [...state.list].sort(sortActiveCompany(action.colName, currentSortOrder, action.dataType)),
    sortOrder: { colName: action.colName, currentColOrder: currentSortOrder }
  }
}
const setError = (state, action) => ({
  ...state,
  errors: [action.message]
})
const getActiveCompanyList = (state, action) => ({
  ...state,
  loading: true
})

const clearActiveCompanyInfoList = (state, action) => ({
  ...initialState
})

const mapItem = item => ({
  ...initialState,
  EmailId: item.emailId,
  MobileNumber: item.mobileNumber, 
  CompanyTypeName: item.companyTypeName,
  CompanyName: item.companyName,
  ContactPersonName: item.contactPersonName,
  ContactPersonMobile: item.contactPersonMobile,
  ContactEmailId: item.contactEmailId,
  ProductOrServiceAboutCompany: item.productOrServiceAboutCompany,  
  HeadCount: item.headCount,
  IndustryName: item.industryName,
  LocationName :item.locationName
})

const getActiveCompanyListResult = (state, action) => {
  const ActiveCompany = action.response || {}
  const data = ActiveCompany.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: [data[1] + ' Records Found.'],
      list: data[0].map(mapItem),
      TotalCompanyCount: data[1]
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: ActiveCompany.errors,
      list: []
    }
  }
}

const getActiveCompanyListError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})