import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const UPDATE_CANDIDATE = 'UPDATE_CANDIDATE'

export const service = async (data) => {
  const url = BASE_API_URL + `/v1/jobtravia/candidateprofile/updatecandidate`
  const options = {
    ...getDefaultOptions(),
    method: 'PATCH',
    body: JSON.stringify(data)
  }
  const response = await window.fetch(url, options)
  const register = await deserializeJsonResponse(response)
  throwExceptionErrors(register)
  return register
}

const mock = request => Promise.resolve({
  id: 1,
  data: [{
    alternateMobileNumber: '',
    ccList: null,
    cityId: 1,
    emailId: 'abc@gmail.com',
    firstName: 'abc',
    isActive: true,
    isDeleted: false,
    lastModifiedBy: 0,
    lastModifiedDate: '2019-06-07T15:46:35.399304+05:30',
    lastName: 'xyz',
    mobileNumber: '1111111111',
    oneTimePassword: '359994',
    registeredVia: 0,
    toList: null,
    userCreatedDate: '0001-01-01T00:00:00',
    userId: 8,
    userLogin: 'abc@gmail.com',
    userName: 'abc xyz',
    userPassword: '1234567',
    userRole: 1
  }]
})

register(UPDATE_CANDIDATE, service, mock)
