import { GET_QUALIFICATION } from '../services/getQualification'
import { GET_SPECIALIZATION } from '../services/getSpecialization'
import { GET_UNIVERSITY } from '../services/getUniversity'
import { GET_SECOND_SPECIALIZATION } from '../services/getSecondSpecialization'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'
import { getYearListInfo } from 'utils/commonUtils'

const GET_YEAR = 'GET_YEAR'

export const getYear = () => ({
  type: GET_YEAR
})

export const initialCertificationList = {
  CertificationName: '',
  Description: ''
}

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialItem = {
  value: 0,
  label: ''
}

const initialState = {
  loading: false,
  errors: initialError,
  IsOtpGenerated: false,
  IsUserRegisterd: false,
  UserId: '',
  YearList: [],
  HighestQualificationList: [],
  SecondHighestQualificationList: [],
  SpecializationList: [],
  SecondSpecializationList: [],
  UniversityList: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_YEAR: return getYearList(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_QUALIFICATION)(action)) return getQualification(state, action)
      if (isAsyncComplete(GET_QUALIFICATION)(action)) return getQualificationResult(state, action)
      if (isAsyncFailure(GET_QUALIFICATION)(action)) return getQualificationError(state, action)

      if (isAsyncOperation(GET_SPECIALIZATION)(action)) return getSpecialization(state, action)
      if (isAsyncComplete(GET_SPECIALIZATION)(action)) return getSpecializationResult(state, action)
      if (isAsyncFailure(GET_SPECIALIZATION)(action)) return getSpecializationError(state, action)

      if (isAsyncOperation(GET_SECOND_SPECIALIZATION)(action)) return getSecondSpecialization(state, action)
      if (isAsyncComplete(GET_SECOND_SPECIALIZATION)(action)) return getSecondSpecializationResult(state, action)
      if (isAsyncFailure(GET_SECOND_SPECIALIZATION)(action)) return getSecondSpecializationError(state, action)

      if (isAsyncOperation(GET_UNIVERSITY)(action)) return getUniversity(state, action)
      if (isAsyncComplete(GET_UNIVERSITY)(action)) return getUniversityResult(state, action)
      if (isAsyncFailure(GET_UNIVERSITY)(action)) return getUniversityError(state, action)
      return state
    default: return state
  }
}

const getYearList = (state, action) => ({
  ...state,
  YearList: getYearListInfo()
})

const getQualification = (state, action) => ({
  ...state,
  loading: true
})

const mapItem = item => ({
  ...initialItem,
  value: item.id,
  label: item.name
})

const getQualificationResult = (state, action) => {
  const qualificationList = action.response || {}
  const data = qualificationList.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      HighestQualificationList: data[0].map(mapItem),
      SecondHighestQualificationList: data[1].map(mapItem)
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: initialError,
      HighestQualificationList: [],
      SecondHighestQualificationList: []
    }
  }
}

const getQualificationError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const getSpecialization = (state, action) => ({
  ...state,
  loading: true
})

const getSpecializationResult = (state, action) => {
  const specializationList = action.response || {}
  const data = specializationList.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      SpecializationList: data[0].map(mapItem)
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: initialError,
      SpecializationList: []
    }
  }
}

const getSpecializationError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const getSecondSpecialization = (state, action) => ({
  ...state,
  loading: true
})

const getSecondSpecializationResult = (state, action) => {
  const secondSpecializationList = action.response || {}
  const data = secondSpecializationList.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      SecondSpecializationList: data[0].map(mapItem)
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: initialError,
      SecondSpecializationList: []
    }
  }
}

const getSecondSpecializationError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const getUniversity = (state, action) => ({
  ...state,
  loading: true
})

const getUniversityResult = (state, action) => {
  const universityList = action.response || {}
  const data = universityList.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      UniversityList: data[0].map(mapItem)
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: initialError,
      UniversityList: []
    }
  }
}

const getUniversityError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
