import { GET_JOB_SEARCH_LIST } from './../services/getSearchedJob'
import { APPLY_JOB } from './../services/applyJob'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'
import { getCommaSeperatedLabel } from 'utils/commonUtils'

const CLEAR_JOB_SEARCH = 'CLEAR_JOB_SEARCH'
const SET_ERROR_MESSAGE_FOR_JOB_SEARCH = 'SET_ERROR_MESSAGE_FOR_JOB_SEARCH'

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE_FOR_JOB_SEARCH,
  message
})

export const clearJobSearch = () => ({
  type: CLEAR_JOB_SEARCH
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  loadingAddEdit: false,
  errors: initialError,
  sortOrder: {},
  jobList: [],
  isJobApplied: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ERROR_MESSAGE_FOR_JOB_SEARCH: return setError(state, action)
    case CLEAR_JOB_SEARCH: return clearJobSearchInfo(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_JOB_SEARCH_LIST)(action)) return getJobSearchList(state, action)
      if (isAsyncComplete(GET_JOB_SEARCH_LIST)(action)) return getJobSearchListResult(state, action)
      if (isAsyncFailure(GET_JOB_SEARCH_LIST)(action)) return getJobSearchListError(state, action)

      if (isAsyncOperation(APPLY_JOB)(action)) return applyJob(state, action)
      if (isAsyncComplete(APPLY_JOB)(action)) return applyJobResult(state, action)
      if (isAsyncFailure(APPLY_JOB)(action)) return applyJobError(state, action)
      return state
    default:
      return state
  }
}

const setError = (state, action) => ({
  ...state,
  errors: [action.message]
})

const clearJobSearchInfo = (state, action) => ({
  ...initialState
})

const getJobSearchList = (state, action) => ({
  ...state,
  loading: true
})

const getLocationNameList = (locationList) => {
  return getCommaSeperatedLabel(locationList)
}

const getExperienceLabel = (item) => {
  return item.minExperience + ' Year - ' + item.maxExperience + ' Year'
}

const mapItem = (item, index) => ({
  ...initialState,
  JobId: item.jobId,
  SerialNumber: index,
  Designation: item.designation,
  CompanyName: item.companyName,
  Experience: getExperienceLabel(item),
  Location: getLocationNameList(item.preferedLocationList),
  AppliedCount: item.appliedCount,
  CheckboxValue: false
})

const getJobSearchListResult = (state, action) => {
  const jobSearch = action.response || {}
  const data = jobSearch.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: !state.isJobApplied && [data[1] + ' Job(s) Found.'],
      jobList: data[0].map(mapItem),
      totalJobCount: data[1],
      isJobApplied: false
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: jobSearch.errors,
      jobList: [],
      totalJobCount: 0,
      isJobApplied: false
    }
  }
}
const getJobSearchListError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const applyJob = (state, action) => ({
  ...state,
  loading: true
})

const applyJobResult = (state, action) => {
  const applyJob = action.response || {}
  const data = applyJob.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      isJobApplied: true
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: initialError,
      isJobApplied: false
    }
  }
}
const applyJobError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
