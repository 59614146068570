import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const POST_NEW_JOB = 'POST_NEW_JOB'

export const service = async (data) => {
  const url = BASE_API_URL + `/v1/jobtravia/jobpost`
  const options = {
    ...getDefaultOptions(),
    method: 'POST',
    body: JSON.stringify(data)
  }
  const response = await window.fetch(url, options)
  const postJob = await deserializeJsonResponse(response)
  throwExceptionErrors(postJob)
  return postJob
}

const mock = request => Promise.resolve({
  id: 1,
  data: [true]
})

register(POST_NEW_JOB, service, mock)
