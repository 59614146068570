import React, { Suspense, lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import App from './App/components'
import { Container } from 'reactstrap'
import LoadingIcon from './LoadingIcon'

const Header = lazy(() => import('Header/components'))
const Footer = lazy(() => import('Footer/components'))
const Register = lazy(() => import('RegisterUser/components'))
const HomePage = lazy(() => import('Website/HomePage/components'))
const Welcome = lazy(() => import('Welcome/components'))
const AboutUs = lazy(() => import('Website/AboutUs'))
const Services = lazy(() => import('Website/Services'))
const Contact = lazy(() => import('Website/Contact'))
const CompanyContactUs = lazy(() => import('Website/CompanyContactUs'))
const PrivacyPolicy = lazy(() => import('RegisterUser/components/PrivacyPolicy'))
const CandidateProfile = lazy(() => import('CandidateProfile/components'))
const TermsAndCondition = lazy(() => import('RegisterUser/components/TermsAndCondition'))
const ForgotPassword = lazy(() => import('ForgotPassword/components'))
const Settings = lazy(() => import('Settings/components'))
const ResetPassword = lazy(() => import('ResetPassword/components'))
const MyServices = lazy(() => import('MyServices/components'))
const Login = lazy(() => import('Login/components'))
const JobSearch = lazy(() => import('JobSearch/components'))
const InviteFriends = lazy(() => import('InviteFriends/components'))
const JobPost = lazy(() => import('JobPost/components'))
const AddJobPost = lazy(() => import('AddJobPost/components'))
const AppliedJobs = lazy(() => import('AppliedJobs/components'))
const JobApplications = lazy(() => import('JobApplications/components'))
const AptitudeTest = lazy(() => import('Welcome/components/AptitudeTest'))
const GroupDiscussion = lazy(() => import('Welcome/components/GroupDiscussion'))
const InterviewSkill = lazy(() => import('Welcome/components/InterviewSkill'))
const PsychomatricTest = lazy(() => import('Welcome/components/PsychomatricTest'))
const ResumeAssessment = lazy(() => import('Welcome/components/ResumeAssessment'))
const TransactionSummary = lazy(() => import('TransactionSummary/components'))
const RegisterCompany = lazy(() => import('RegisterCompany/components'))
const CompanyWelcome = lazy(() => import('CompanyWelcome/components'))
const CompanyProfile = lazy(() => import('CompanyProfile/components'))
const CompanySettings = lazy(() => import('CompanySettings/components'))
const ActiveCandidateList = lazy(() => import('ActiveCandidateList/components'))
const CandidateSearch = lazy(() => import('CandidateSearch/components'))
const CompanyMyServices = lazy(() => import('CompanyMyServices/components'))
const ManageUser = lazy(() => import('ManageUsers/components'))
const AddCandidates = lazy(() => import('AddCandidates/components'))
const AddedCandidateList = lazy(() => import('AddedCandidateList/components'))
const ActiveCompanyList = lazy(() => import('ActiveCompanyList/components'))

const RootRouter = props => (
  <App>
    <Container className='h-100 container' fluid>
      <Suspense fallback={LoadingIcon({})}>
        <Switch>
          <Route exact path={'/'} render={() => <HomePage />} />
          <Route exact path={'/header'} render={() => <Header />} />
          <Route exact path={'/footer'} render={() => <Footer />} />
          <Route exact path={'/register'} render={() => <Register />} />
          <Route exact path={'/login'} render={() => <Login />} />
          <Route exact path={'/welcome'} render={() => <Welcome />} />
          <Route exact path={'/aboutus'} render={() => <AboutUs />} />
          <Route exact path={'/services'} render={() => <Services />} />
          <Route exact path={'/contact'} render={() => <Contact />} />
          <Route exact path={'/companycontactus'} render={() => <CompanyContactUs />} />
          <Route exact path={'/termsandcondition'} render={() => <TermsAndCondition />} />
          <Route exact path={'/privacypolicy'} render={() => <PrivacyPolicy />} />
          <Route exact path={'/forgotpassword'} render={() => <ForgotPassword />} />
          <Route exact path={'/candidateProfile'} render={() => <CandidateProfile />} />
          <Route exact path={'/settings'} render={() => <Settings />} />
          <Route exact path={'/resetpassword'} render={() => <ResetPassword />} />
          <Route exact path={'/myservices'} render={() => <MyServices />} />
          <Route exact path={'/myservices/:response'} render={() => <MyServices />} />
          <Route exact path={'/jobsearch'} render={() => <JobSearch />} />
          <Route exact path={'/invitefriends'} render={() => <InviteFriends />} />
          <Route exact path={'/jobs'} render={() => <JobPost />} />
          <Route exact path={'/jobpost'} render={() => <AddJobPost />} />
          <Route exact path={'/jobpost/:jobId'} render={() => <AddJobPost />} />
          <Route exact path={'/appliedjobs'} render={() => <AppliedJobs />} />
          <Route exact path={'/jobapplications'} render={() => <JobApplications />} />
          <Route exact path={'/jobapplications/:jobId'} render={() => <JobApplications />} />
          <Route exact path={'/aptitudeTest'} render={() => <AptitudeTest />} />
          <Route exact path={'/resumeAssessment'} render={() => <ResumeAssessment />} />
          <Route exact path={'/interviewSkill'} render={() => <InterviewSkill />} />
          <Route exact path={'/psychomatricTest'} render={() => <PsychomatricTest />} />
          <Route exact path={'/groupDiscussion'} render={() => <GroupDiscussion />} />
          <Route exact path={'/transactionSummary'} render={() => <TransactionSummary />} />
          <Route exact path={'/registercompany'} render={() => <RegisterCompany />} />
          <Route exact path={'/companywelcome'} render={() => <CompanyWelcome />} />
          <Route exact path={'/companyProfile'} render={() => <CompanyProfile />} />
          <Route exact path={'/activecandidatelist'} render={() => <ActiveCandidateList />} />
          <Route exact path={'/companySettings'} render={() => <CompanySettings />} />
          <Route exact path={'/candidatesearch'} render={() => <CandidateSearch />} />
          <Route exact path={'/companymyservices'} render={() => <CompanyMyServices />} />
          <Route exact path={'/manageuser'} render={() => <ManageUser />} />
          <Route exact path={'/addcandidates'} render={() => <AddCandidates />} /> 
          <Route exact path={'/addcandidates/:importedCandidateUserId'} render={() => <AddCandidates />} />
          <Route exact path={'/addedcandidatelist'} render={() => <AddedCandidateList />} /> 
          <Route exact path={'/activecompanylist'} render={() => <ActiveCompanyList />} />
        </Switch>
      </Suspense>
    </Container>
  </App>
)

export default RootRouter
