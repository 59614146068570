import dateFns from 'date-fns'
import { isNil } from 'lodash'

export const convertDate = (date, format = 'MM/DD/YYYY') => {
  if (isNil(date)) return ''
  return dateFns.format(new Date(date), format)
}

export const convertDateTime = (date, format = 'DD-MM-YYYY HH:mm:ss') => {
  if (isNil(date)) return ''
  return dateFns.format(new Date(date), format)
}
