const SET_PROGRESS_STATUS = 'SET_PROGRESS_STATUS'
const SET_PANEL_OPEN_CLOSE = 'SET_PANEL_OPEN_CLOSE'

export const setProgressStatus = (value) => ({
  type: SET_PROGRESS_STATUS,
  value
})

export const setPanelOpenClose = (values = {}) => ({
  type: SET_PANEL_OPEN_CLOSE,
  setValues: values
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  ProgressStatus: 0,
  openClosePersonalDetails: true,
  openCloseEducationalDetails: true,
  openCloseProjectdetails: true,
  openCloseWorkHistory: true,
  openCloseEmploymentHistory: true,
  openCloseUploadResume: true
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PROGRESS_STATUS: return setProgressStatusInfo(state, action)
    case SET_PANEL_OPEN_CLOSE: return setPanelOpenCloseInfo(state, action)
    default: return state
  }
}

const setProgressStatusInfo = (state, action) => ({
  ...state,
  ProgressStatus: action.value
})

const setPanelOpenCloseInfo = (state, action) => ({
  ...state,
  ...action.setValues
})
