import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const GET_ACTIVE_COMPANY_LIST = 'GET_ACTIVE_COMPANY_LIST'
export const service = async (data,pagenumber) => {  
  const url = BASE_API_URL + `/v1/jobtravia/companyprofile/getActiveCompanyList`
  const body = {...data, Pagenumber: pagenumber }
  const options = {
    ...getDefaultOptions(),
    method: 'POST',
    body: JSON.stringify(body)
  }
  const response = await window.fetch(url, options)
  const activeCompanyList = await deserializeJsonResponse(response)
  throwExceptionErrors(activeCompanyList)
  return activeCompanyList
}

const mock = request => Promise.resolve({
  id: 1,
  data: [[
    { FirstName:'ABC',  LastName: 'DEF',UserName: 'ABC DEF',UserLogin:'test1@gmail.com',UserCreatedDate:'12-07-2019',MobileNumber:'999999999',AlternateMobileNumber:'111111111',RegisterVia:'FaceBook',LocationName:'Pune' },
    { FirstName:'PQR',  LastName: 'STU',UserName: 'PQR STU',UserLogin:'test2@gmail.com',UserCreatedDate:'12-07-2019',MobileNumber:'888888888',AlternateMobileNumber:'222222222',RegisterVia:'Instagram',LocationName:'Mumbai' },
    { FirstName:'XYZ',  LastName: 'ABC',UserName: 'XYZ ABC',UserLogin:'test3@gmail.com',UserCreatedDate:'12-07-2019',MobileNumber:'777777777',AlternateMobileNumber:'333333333',RegisterVia:'Manual',LocationName:'Nagpur' },]]
})

register(GET_ACTIVE_COMPANY_LIST, service, mock)
