import { GET_COMPANY } from '../services/getCompany'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'

const SET_FUNCTION_MODE = 'SET_FUNCTION_MODE'
const CLEAR_COMPANY_EMAIL_CHANGE = 'CLEAR_COMPANY_EMAIL_CHANGE'
const SET_PROGRESS_STATUS_COMPANY = 'SET_PROGRESS_STATUS_COMPANY'
const SET_PANEL_OPEN_CLOSE_COMPANY = 'SET_PANEL_OPEN_CLOSE_COMPANY'
const SET_COMPANY_CHANGE_EMAIL_ERROR_MESSAGE = 'SET_COMPANY_CHANGE_EMAIL_ERROR_MESSAGE'

export const setFunctionMode = (value) => ({
  type: SET_FUNCTION_MODE,
  value
})

export const setProgressStatus = (value) => ({
  type: SET_PROGRESS_STATUS_COMPANY,
  value
})

export const setPanelOpenClose = (values = {}) => ({
  type: SET_PANEL_OPEN_CLOSE_COMPANY,
  setValues: values
})

export const clearCompanyEmailChanged = () => ({
  type: CLEAR_COMPANY_EMAIL_CHANGE
})
export const setCompanyChangeEmailErrorMessage = (message) => ({
  type: SET_COMPANY_CHANGE_EMAIL_ERROR_MESSAGE,
  message
})
const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  EmailId: '',
  CandidateId: 0,
  OneTimePassword: '',
  CurrentEmailId: '',
  ConfirmEmailId: '',
  MobileNumber: '',
  AlternateMobileNumber: '',
  CandidateStatus: 1,
  FirstName: '',
  LastName: '',
  FullName: '',
  UserPassword: '',
  UserId: 0,
  ImageUrl: '',
  VideoUrl: '',
  LocationId: 0,
  LocationName: '',
  LanguageSupport: '',
  DateOfBirth: new Date(),
  Gender: '',
  MaritalStatus: '',
  IsHandicaped: '',
  IsEmailChanged: false,
  IsOtpGenerated: false,
  FunctionMode: '',
  CandidateType: 0,
  LanguageKnownList: [],
  CertificationDetailsList: [],
  EducationDetailsList: [],
  ProjectDetails: {},
  VideoBase64String: '',
  ImageBase64String: '',
  FileBase64String: '',
  WorkExperienceList: [],
  EmploymentId: 0,
  CompanyName: '',
  Designation: '',
  EmploymentDurationFrom: new Date(),
  EmploymentDurationTo: new Date(),
  EmploymentDurationToTillDate: false,
  Salary: 0,
  NoticePeriodId: 0,
  PreviousExperienceList: [],
  ResumeBase64String: '',
  ResumeUrl: '',
  ResumeSize: 0,
  RewardPoints: 0,
  SubscriptionPlanId: 0,
  SubscriptionAmount: 0,
  SubscriptionDays: 0,
  UserRole: 0,
  CompanyId: 0,
  JobPostCompanyName: '',
  ProgressStatus: 0,
  SubsidiaryCompanyName: '',
  BusinessType: '',
  CompanyDetails: '',
  RegisteredAddress: '',
  ContactPersonMobile: '',
  ContactEmailId: '',
  AlternateEmailId: '',
  AlternateContactPersonName: '',
  ContactPersonDesignation: '',
  AlternateContactPersonMobile: '',
  openCloseCompanyProfileDetails: true,
  openCloseLeadershipDetails: true,
  openCloseCompanyDetails: true,
  openCloseRecruitmentDetails: true,
  CompanyUrl: '',
  CompanyStatus: 0,
  IndustryTypeId: 0,
  IndustryName: '',
  ProductOrServiceAboutCompany: '',
  CompanyBranchLocationList: '',
  HeadCount: 0,
  TargetCustomerOrClient: '',
  FinancialTurnoverOfTheCompany: '',
  ExpansionsPlanIfAny: '',
  LegalNote: '',
  AttritionRatio: '',
  NoOfInternsOrApprenticeshipRequireInOneYear: 0,
  NoOfFreshersRequiredInOneYear: 0,
  NoOfFullTimeRequiredInOneYears: 0,
  CompanyType :0
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_FUNCTION_MODE: return setFunctionModeInfo(state, action)
    case CLEAR_COMPANY_EMAIL_CHANGE: return clearCompanyEmailChangedInfo(state, action)
    case SET_PROGRESS_STATUS_COMPANY: return setProgressStatusInfo(state, action)
    case SET_PANEL_OPEN_CLOSE_COMPANY: return setPanelOpenCloseInfo(state, action)
    case SET_COMPANY_CHANGE_EMAIL_ERROR_MESSAGE: return setCompanyChangeEmailErrorMessageInfo(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_COMPANY)(action)) return getCompany(state, action)
      if (isAsyncComplete(GET_COMPANY)(action)) return getCompanyResult(state, action)
      if (isAsyncFailure(GET_COMPANY)(action)) return getCompanyError(state, action)
      return state
    default: return state
  }
}

const clearCompanyEmailChangedInfo = (state, action) => ({
  ...state,
  errors: [],
  IsEmailChanged: false,
  IsOtpGenerated: false,
  CandidateId: 0,
  CurrentEmailId: '',
  LanguageKnownList: [],
  CertificationDetailsList: [],
  EducationDetailsList: [],
  CandidateType: 0,
  FunctionMode: '',
  ProjectDetails: {},
  LocationId: 0,
  LocationName: '',
  CompanyId: 0,
  UserRole:0
})

const setFunctionModeInfo = (state, action) => ({
  ...state,
  FunctionMode: action
})

const setProgressStatusInfo = (state, action) => ({
  ...state,
  ProgressStatus: action.value
})

const setPanelOpenCloseInfo = (state, action) => ({
  ...state,
  ...action.setValues
})
const setCompanyChangeEmailErrorMessageInfo = (state, action) => ({
  ...state,
  errors: [action.message]
})
const getCompany = (state, action) => ({
  ...state,
  loading: true
})

const getCompanyResult = (state, action) => {
  const company = action.response || {}
  const data = company.data
  if (!isNil(data)) {
    const companyProfile = data[0]
    return {
      ...state,
      loading: false,
      errors: initialError,
      IsEmailChanged: false,
      CurrentEmailId: companyProfile.emailId,
      CompanyStatus: companyProfile.companyStatus,
      UserId: companyProfile.userId,
      CompanyId: companyProfile.companyId,
      MobileNumber: companyProfile.mobileNumber,
      AlternateMobileNumber: companyProfile.alternateMobileNumber,
      FirstName: companyProfile.firstName,
      LastName: companyProfile.lastName,
      ImageUrl: companyProfile.imageUrl,
      VideoUrl: companyProfile.videoUrl,
      UserPassword: companyProfile.userPassword,
      LocationId: companyProfile.locationId,
      LocationName: companyProfile.locationName,
      Gender: companyProfile.gender,
      CompanyType: companyProfile.companyType,
      VideoBase64String: companyProfile.videoBase64String,
      ImageBase64String: companyProfile.imageBase64String,
      FileBase64String: companyProfile.otherBase64String,
      CompanyName: companyProfile.companyName,
      SubsidiaryCompanyName: companyProfile.subsidiaryCompanyName,
      BusinessType: companyProfile.businessType,
      CompanyProfile: companyProfile.companyProfile,
      RegisteredAddress: companyProfile.registeredAddress,
      ContactPersonMobile: companyProfile.contactPersonMobile,
      ContactEmailId: companyProfile.contactEmailId,
      AlternateContactPersonName: companyProfile.alternateContactPersonName,
      AlternateEmailId: companyProfile.alternateEmailId,
      ContactPersonDesignation: companyProfile.contactPersonDesignation,
      EmailId: companyProfile.emailId,
      AlternateContactPersonMobile: companyProfile.alternateContactPersonMobile,
      Designation: !isNil(companyProfile.candidateEmploymentHistory) ? companyProfile.candidateEmploymentHistory.designation : '',
      SalaryInLacs: !isNil(companyProfile.candidateEmploymentHistory) ? companyProfile.candidateEmploymentHistory.salaryInLacs : '',
      SalaryInThousands: !isNil(companyProfile.candidateEmploymentHistory) ? companyProfile.candidateEmploymentHistory.salaryInThousands : '',
      MultimediaId: companyProfile.resumeMultimediaId,
      ResumeBase64String: companyProfile.resumeBase64String,
      ResumeUrl: companyProfile.resumeUrl,
      ResumeSize: companyProfile.resumeSize,
      SubscriptionPlanId: companyProfile.subscriptionPlanId,
      SubscriptionAmount: companyProfile.amount,
      SubscriptionDays: companyProfile.noOfDays,
      ReferByUserId: companyProfile.referByUserId,
      FullName: companyProfile.firstName + ' ' + (!isNil(companyProfile.lastName) ? companyProfile.lastName : ''),
      UserRole: companyProfile.userRole,
      CompanyUrl: companyProfile.companyUrl,
      IndustryTypeId: companyProfile.industryTypeId,
      IndustryName: companyProfile.industryName,
      ProductOrServiceAboutCompany: companyProfile.productOrServiceAboutCompany,
      CompanyBranchLocationList: companyProfile.companyBranchLocationList,
      HeadCount: companyProfile.headCount,
      TargetCustomerOrClient: companyProfile.targetCustomerOrClient,
      FinancialTurnoverOfTheCompany: companyProfile.financialTurnoverOfTheCompany,
      ExpansionsPlanIfAny: companyProfile.expansionsPlanIfAny,
      LegalNote: companyProfile.legalNote,
      AttritionRatio: companyProfile.attritionRatio,
      NoOfInternsOrApprenticeshipRequireInOneYear: companyProfile.noOfInternsOrApprenticeshipRequireInOneYear,
      NoOfFreshersRequiredInOneYear: companyProfile.noOfFreshersRequiredInOneYear,
      NoOfFullTimeRequiredInOneYears: companyProfile.noOfFullTimeRequiredInOneYears,
      IsCompanyLoaded:1
    }
  } else {
    return {
      ...initialState
    }
  }
}

const getCompanyError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
