export const OTP_BUTTON_TOOLTIP = 'Clickable only when Email and Contact Number details are provided'
export const MULITIMEDIA_STORAGE_FOLDER = 'MultiMediaStorage/'
export const MULITIMEDIA_STORAGE_FOLDER_COMPANY = 'MultiMediaStorage/Company/'
export const FILE_SIZE_ERROR_MESSAGE = 'File size must be less than 4mb.'
export const VIDEO_SIZE_ERROR_MESSAGE = 'Video size must be less than 15mb.'
export const VIDEO_FORMAT_ERROR_MESSAGE = 'Video must be in mp4 format.'
export const IMAGE_SIZE_ERROR_MESSAGE = 'Image size must be less than 4mb.'
export const IMAGE_FORMAT_ERROR_MESSAGE = 'Image must be in .PNG, .JPG or .JPEG format.'
export const MASKED_COMPANY_NAME = 'XXXXXXXXXX'
export const WORD_FILE_FORMAT = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
export const MSWORD_FILE_FORMAT = 'application/msword'
export const PDF_FILE_FORMAT = 'application/pdf'
export const RTF_FILE_FORMAT_1 = 'application/rtf'
export const RTF_FILE_FORMAT_2 = 'application/x-rtf'
export const RTF_FILE_FORMAT_3 = 'text/rtf'
export const RTF_FILE_FORMAT_4 = 'text/richtext'
export const MAX_FILE_SIZE = 4194304
export const MAX_VIDEO_SIZE = 15728640
export const LANGUAGE_SUPPORT_ENGLISH = 1
export const VALID_VIDEO_FORMAT = 'video/mp4'
export const JOBTRAVIA_ADMIN_DOMAIN = '@jobtravia.com'
export const COMPLETE_PROFILE_PERCENT = 100
export const ACTIVE_CANDIDATE = 2
export const REGISTERED_CANDIDATE = 3

export const CANDIDATE = 1
export const COMPANY = 2
export const COMPANY_SUB_USER =4

export const GENDER_LIST = [
  { value: 1, label: 'Male' },
  { value: 2, label: 'Female' },
  { value: 3, label: 'Transgender' }
]
export const CANDIDATE_TYPE_LIST = [
  { value: 1, label: 'Intern' },
  { value: 2, label: 'Fresher' },
  { value: 3, label: 'Experience' }
]
export const EMPLOYMENT_TYPELIST = [
  { value: 1, label: 'Full Time/Permanent' },
  { value: 2, label: 'Temporary/Contract' },
  { value: 3, label: 'Part Time' },
  { value: 4, label: 'Freelancer' }
]
export const NOTICE_PERIOD_LIST = [
  { value: 1, label: 'Immediate' },
  { value: 2, label: '1 Week' },
  { value: 3, label: '15 Days' },
  { value: 4, label: '1 Month' },
  { value: 5, label: '45 Days' },
  { value: 6, label: '2 Months' },
  { value: 7, label: '3 Months' },
  { value: 8, label: 'Currently Serving Notice Period' }
]
export const YES_NO_LIST = [
  { value: 1, label: 'Yes' },
  { value: 2, label: 'No' }
]
export const RESPOSIVE_VOICE_LANGUAGE = 'UK English Female'

export const BUSINESS_TYPE = [
  { value: 1, label: 'Proprietor' },
  { value: 2, label: 'LLP' },
  { value: 3, label: '1 Person Company' },
  { value: 4, label: 'OPC' },
  { value: 5, label: 'Pvt. Ltd.' },
  { value: 6, label: 'Ltd' },
  { value: 7, label: 'Non-Listed' },
  { value: 8, label: 'Non limited Listed' },
  { value: 9, label: 'Individual Firms' },
  { value: 10, label: 'Associate' }
]
