import React from 'react'
import { Button, SelectDropdown } from 'bizsense-components'
import './index.scss'
import { isNil } from 'lodash'
import { PROFILE_MENU_LIST, COMPANY_MENU_LIST } from './constants'
const UserRole = 1

const DesktopHeader = props =>
  <div id='header-container-id' className='header-container'>
    <div id='header-component-id' className='header-component'>
      <img id='logo-id' className='logo' onClick={props.onLogoClick} src={require('images/logo.svg')} alt='JabTravia' />
      {(window.location.href.indexOf('/register') < 0 && window.location.href.indexOf('/termsandcondition') < 0 && window.location.href.indexOf('/privacypolicy') < 0 &&
        window.location.href.indexOf('/psychomatricTest') < 0 && window.location.href.indexOf('/interviewSkill') < 0 && window.location.href.indexOf('/resumeAssessment') < 0 &&
        window.location.href.indexOf('/groupDiscussion') < 0 && window.location.href.indexOf('/aptitudeTest') < 0 &&
        window.location.href.indexOf('/login') < 0 && window.location.href.indexOf('/welcome') < 0 && window.location.href.indexOf('/companywelcome') < 0 && window.location.href.indexOf('/companysettings') < 0 &&
        window.location.href.indexOf('/candidateProfile') < 0 && window.location.href.indexOf('/settings') < 0 &&
        window.location.href.indexOf('/companyProfile') < 0 && window.location.href.indexOf('/companywelcome') < 0 &&
        window.location.href.indexOf('/myservices') < 0 && window.location.href.indexOf('/jobsearch') < 0 &&
        window.location.href.indexOf('/invitefriends') < 0 && window.location.href.indexOf('/jobs') < 0 &&
        window.location.href.indexOf('/appliedjobs') < 0 && window.location.href.indexOf('/jobpost') < 0 &&
        window.location.href.indexOf('/jobapplications') < 0 && window.location.href.indexOf('/transactionSummary') < 0 &&
        window.location.href.indexOf('/activecandidatelist') < 0 && window.location.href.indexOf('/candidatesearch') < 0 &&
        window.location.href.indexOf('/companymyservices') < 0 && window.location.href.indexOf('/manageuser') < 0 && window.location.href.indexOf('/addcandidates') < 0 &&
        window.location.href.indexOf('/addedcandidatelist') < 0 && window.location.href.indexOf('/activecompanylist') < 0)
        ? (<div id='header-button-container-id' className='header-button-container'>
          <Button id='about-us-click-id' link className='round-link-button' onClick={props.onAboutUsClick} >About Us</Button>
          <Button id='servcices-click-id' link className='round-link-button' onClick={props.onServicesClick}>Services</Button>
          <Button id='contact-us-id' link className='round-link-button' onClick={props.onContactUsClick}>Contact Us</Button>
          <Button id='login-id' bsStyle='custom' onClick={props.onLoginClick} className='round-button'>Log in</Button>
          <Button id='register-id' bsStyle='custom' onClick={props.onRegisterClick} className='round-button'>Register</Button>
        </div>)
        : window.location.href.indexOf('/register') < 0 && window.location.href.indexOf('/login') < 0 &&
        window.location.href.indexOf('/termsandcondition') < 0 && window.location.href.indexOf('/privacypolicy') < 0 &&
        window.location.href.indexOf('/psychomatricTest') < 0 && window.location.href.indexOf('/interviewSkill') < 0 && window.location.href.indexOf('/resumeAssessment') < 0 &&
        window.location.href.indexOf('/groupDiscussion') < 0 && window.location.href.indexOf('/aptitudeTest') < 0 &&
        <div id='profile-menu-section-id' className='profile-menu-section'>
          <SelectDropdown
            bsSize='sm'
            placeholder={props.header.FirstName}
            name='ProfileMenuId'
            options={!isNil(props.header) && props.header.UserRole === UserRole ? PROFILE_MENU_LIST : COMPANY_MENU_LIST}
            onChange={props.onProfileMenuClick}
            className='header-dropdown'
            value=''
          />
        </div>
      }
    </div>
  </div>

export default DesktopHeader
