import { GET_PREFERED_ROLE_FOR_ADD_JOB } from './../services/getPreferedRole'
import { GET_JOB_BY_ID } from './../services/getJobById'
import { POST_NEW_JOB } from './../services/postNewJob'
import { GET_SPECIALIZATION_FOR_ADD_JOB } from './../services/getPreferedSpecializationList'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'

const CLEAR_JOB_POST_DETAILS = 'CLEAR_JOB_POST_DETAILS'
export const SET_ERROR_MESSAGE_JOB_POST = 'SET_ERROR_MESSAGE_JOB_POST'

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE_JOB_POST,
  message
})

export const clearJobPostDetails = () => ({
  type: CLEAR_JOB_POST_DETAILS
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  PreferedRoleList: [],
  PreferedSpecializationList: [],
  IsJobPost: false,
  JobId: 0
}

const initialItem = {
  value: 0,
  label: ''
}

const initialOption = {
  label: '',
  options: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_JOB_POST_DETAILS: return clearJobPostDetailsInfo(state, action)
    case SET_ERROR_MESSAGE_JOB_POST : return setError(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_PREFERED_ROLE_FOR_ADD_JOB)(action)) return getPreferedRole(state, action)
      if (isAsyncComplete(GET_PREFERED_ROLE_FOR_ADD_JOB)(action)) return getPreferedRoleResult(state, action)
      if (isAsyncFailure(GET_PREFERED_ROLE_FOR_ADD_JOB)(action)) return getPreferedRoleError(state, action)

      if (isAsyncOperation(GET_JOB_BY_ID)(action)) return getJobById(state, action)
      if (isAsyncComplete(GET_JOB_BY_ID)(action)) return getJobByIdResult(state, action)
      if (isAsyncFailure(GET_JOB_BY_ID)(action)) return getJobByIdError(state, action)

      if (isAsyncOperation(POST_NEW_JOB)(action)) return postNewJob(state, action)
      if (isAsyncComplete(POST_NEW_JOB)(action)) return postNewJobResult(state, action)
      if (isAsyncFailure(POST_NEW_JOB)(action)) return postNewJobError(state, action)

      if (isAsyncOperation(GET_SPECIALIZATION_FOR_ADD_JOB)(action)) return getPreferedSpecialization(state, action)
      if (isAsyncComplete(GET_SPECIALIZATION_FOR_ADD_JOB)(action)) return getPreferedSpecializationResult(state, action)
      if (isAsyncFailure(GET_SPECIALIZATION_FOR_ADD_JOB)(action)) return getPreferedSpecializationError(state, action)
      return state
    default: return state
  }
}

const setError = (state, action) => ({
  ...state,
  errors: [action.message]
})

const clearJobPostDetailsInfo = (state, action) => ({
  ...initialState
})

const getPreferedRole = (state, action) => ({
  ...state,
  loading: true
})

const mapItem = item => ({
  ...initialItem,
  value: item.id,
  label: item.name
})

const mapRoleItem = item => ({
  ...initialOption,
  label: item.functionalAreaSubName,
  options: item.roleList.map(mapItem)
})

const getPreferedRoleResult = (state, action) => {
  const preferedRole = action.response || {}
  const data = preferedRole.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      PreferedRoleList: data.map(mapRoleItem)
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: initialError,
      PreferedRoleList: []
    }
  }
}

const getPreferedRoleError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const getJobById = (state, action) => ({
  ...state,
  loading: true
})

const getJobByIdResult = (state, action) => {
  const job = action.response || {}
  const data = job.data
  if (!isNil(data)) {
    const jobPost = data[0]
    return {
      ...state,
      loading: false,
      errors: initialError,
      JobId: jobPost.jobId,
      Designation: jobPost.designation,
      CompanyName: jobPost.companyName,
      MinExperience: jobPost.minExperience,
      MaxExperience: jobPost.maxExperience,
      Age: jobPost.age,
      SalaryMinLacs: jobPost.salaryMinLacs,
      SalaryMinThousand: jobPost.salaryMinThousand,
      SalaryMaxLacs: jobPost.salaryMaxLacs,
      SalaryMaxThousand: jobPost.salaryMaxThousand,
      NumberOfOpenPositions: jobPost.numberOfOpenPositions,
      CandidateType: jobPost.candidateType,
      EmploymentType: jobPost.employmentType,
      NoticePeriod: jobPost.noticePeriod,
      DesiredProfile: jobPost.desiredProfile,
      GoodToHave: jobPost.goodToHave,
      KeySkills: jobPost.keySkills,
      ValidUpto: jobPost.validUpto,
      PreferedIndustryTypeList: jobPost.preferedIndustryTypeList,
      PreferedFunctionalAreaList: jobPost.preferedFunctionalAreaList,
      PreferedRoleList: jobPost.preferedRoleList,
      PreferedLocationList: jobPost.preferedLocationList,
      HigheshQualificationList: jobPost.higheshQualificationList,
      GenderList: jobPost.genderList,
      JobCode: jobPost.jobCode,
      CompanyProfile: jobPost.companyProfile,
      CompanyUrl: jobPost.companyUrl,
      ContactPersonName: jobPost.contactPersonName,
      ContactPersonMobile: jobPost.contactPersonMobile,
      ContactEmailId: jobPost.contactEmailId,
      AlternateContactPersonName: jobPost.alternateContactPersonName,
      AlternateContactPersonMobile: jobPost.alternateContactPersonMobile,
      AlternateEmailId: jobPost.alternateEmailId,
      JobTitle: jobPost.jobTitle,
      AppliedCount: jobPost.appliedCount,
      PreferedSpecializationList: jobPost.preferedSpecializationList
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: initialError
    }
  }
}

const getJobByIdError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const postNewJob = (state, action) => ({
  ...state,
  loading: true
})

const postNewJobResult = (state, action) => {
  const postJobResult = action.response || {}
  const data = postJobResult.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: ['job post Successfully.'],
      IsJobPost: true
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: postJobResult.errors,
      IsJobPost: false
    }
  }
}

const postNewJobError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors,
  IsJobPost: false
})

const getPreferedSpecialization = (state, action) => ({
  ...state,
  loading: true
})

const getPreferedSpecializationResult = (state, action) => {
  const preferedRole = action.response || {}
  const data = preferedRole.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      PreferedSpecializationList: data[0].map(mapItem)
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: initialError,
      PreferedSpecializationList: []
    }
  }
}

const getPreferedSpecializationError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
