import { isNil } from 'lodash'
import { GET_JOB_POST_LIST } from '../services/getJobPostList'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { convertDate } from 'utils/dateUtils'

export const SORT_JOB_POST = 'SORT_JOB_POST'

export const sortJobPostList = (colName, currentColOrder, dataType) => ({
  type: SORT_JOB_POST,
  colName,
  currentColOrder,
  dataType
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  loadingAddEdit: false,
  errors: initialError,
  sortOrder: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SORT_JOB_POST: return sortJobPostInfo(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_JOB_POST_LIST)(action)) return getJobPostList(state, action)
      if (isAsyncComplete(GET_JOB_POST_LIST)(action)) return getJobPostListResult(state, action)
      if (isAsyncFailure(GET_JOB_POST_LIST)(action)) return getJobPostListError(state, action)
      return state
    default:
      return state
  }
}

const sortJobPost = (colName, colOrder, dataType) => (a, b) => {
  const aValue = isNil(a[colName]) ? '' : dataType !== 'number' ? a[colName].toLowerCase() : a[colName]
  const bValue = isNil(b[colName]) ? '' : dataType !== 'number' ? b[colName].toLowerCase() : b[colName]
  if (colOrder === 'desc') {
    if (aValue > bValue) return -1
    if (aValue < bValue) return 1
    return 0
  }
  if (aValue < bValue) return -1
  if (aValue > bValue) return 1
  return 0
}

export const sortJobPostInfo = (state, action) => {
  const currentSortOrder = action.currentColOrder === 'asc' ? 'desc' : 'asc'
  return {
    ...state,
    list: [...state.list].sort(sortJobPost(action.colName, currentSortOrder, action.dataType)),
    sortOrder: { colName: action.colName, currentColOrder: currentSortOrder }
  }
}

const getJobPostList = (state, action) => ({
  ...state,
  loading: true
})

const mapItem = item => ({
  ...initialState,
  JobId: item.jobId,
  JobCode: item.jobCode,
  Designation: item.designation,
  CreatedDate: convertDate(item.createdDate, 'DD-MM-YYYY'),
  LastModifiedDate: convertDate(item.lastModifiedDate, 'DD-MM-YYYY'),
  JobStatus: item.jobStatus,
  CompanyName: item.companyName,
  ViewCount: item.viewCount,
  AppliedCount: item.appliedCount
})

const getJobPostListResult = (state, action) => {
  const jobPost = action.response || {}
  const data = jobPost.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: [data[1] + ' Job(s) Found.'],
      list: data[0].map(mapItem),
      totalJobCount: data[1]
    }
  } else {
    return {
      ...state,
      loading: false,
      errors :'',
      list: [],
      totalJobCount :0
    }
  }
}

const getJobPostListError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
