import { GET_APPLIED_JOB_LIST } from '../services/getAppliedJob'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { convertDate } from 'utils/dateUtils'
import { getCommaSeperatedLabel } from 'utils/commonUtils'
import { isNil } from 'lodash'

const CLEAR_APPLIED_JOBS = 'CLEAR_APPLIED_JOBS'
const SET_ERROR_MESSAGE_FOR_APPLIED_JOB = 'SET_ERROR_MESSAGE_FOR_APPLIED_JOB'

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  loadingAddEdit: false,
  errors: initialError,
  sortOrder: {},
  jobList: [],
  isJobApplied: false
}

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE_FOR_APPLIED_JOB,
  message
})

export const clearAppliedJobs = () => ({
  type: CLEAR_APPLIED_JOBS
})

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ERROR_MESSAGE_FOR_APPLIED_JOB: return setError(state, action)
    case CLEAR_APPLIED_JOBS: return clearAppliedJobsInfo(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_APPLIED_JOB_LIST)(action)) return getAppliedJobsList(state, action)
      if (isAsyncComplete(GET_APPLIED_JOB_LIST)(action)) return getAppliedJobsListResult(state, action)
      if (isAsyncFailure(GET_APPLIED_JOB_LIST)(action)) return getAppliedJobsListError(state, action)
      return state
    default:
      return state
  }
}

const setError = (state, action) => ({
  ...state,
  errors: [action.message]
})

const clearAppliedJobsInfo = (state, action) => ({
  ...initialState
})

const getAppliedJobsList = (state, action) => ({
  ...state,
  loading: true
})

const getLocationNameList = (locationList) => {
  return getCommaSeperatedLabel(locationList)
}

const getExperienceLabel = (item) => {
  return item.minExperience + ' Year - ' + item.maxExperience + ' Year'
}

const mapItem = (item, index) => ({
  ...initialState,
  JobId: item.jobId,
  SerialNumber: index,
  Designation: item.designation,
  CompanyName: item.companyName,
  Experience: getExperienceLabel(item),
  Location: getLocationNameList(item.preferedLocationList),
  JobAppliedOn: convertDate(item.jobAppliedOn, 'DD-MM-YYYY'),
  CheckboxValue: false
})

const getAppliedJobsListResult = (state, action) => {
  const jobList = action.response || {}
  const data = jobList.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      jobList: data[0].map(mapItem),
      totalJobCount: data[1]
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: jobList.errors,
      jobList: [],
      totalJobCount: 0
    }
  }
}
const getAppliedJobsListError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
