import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const UPLOAD_PROFILE_SERVICE = 'UPLOAD_PROFILE_SERVICE'

export const service = async (image, id) => {
  const url = BASE_API_URL + '/v1/jobtravia/candidateprofile/profile'
  const body = { Base64String: image, UserId: id }
  const options = {
    ...getDefaultOptions(),
    method: 'POST',
    body: JSON.stringify(body)
  }
  const response = await window.fetch(url, options)
  const profilePicture = await deserializeJsonResponse(response)
  throwExceptionErrors(profilePicture)
  return profilePicture
}

const mock = request => Promise.resolve([
  921213
])

register(UPLOAD_PROFILE_SERVICE, service, mock)
