import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const GET_JOB_BY_ID = 'GET_JOB_BY_ID'

export const service = async (id, userId = 0) => {
  const url = BASE_API_URL + `/v1/jobtravia/jobpost/getjobbyid/` + id + '/' + userId
  const options = {
    ...getDefaultOptions(),
    method: 'GET'
  }
  const response = await window.fetch(url, options)
  const jobpost = await deserializeJsonResponse(response)
  throwExceptionErrors(jobpost)
  return jobpost
}

const mock = request => Promise.resolve({
  id: 1,
  data: [{
    jobId: 1,
    designation: 'Developer',
    Location: 'Nagpur',
    keySkills: 'APS.NET, REACT',
    education: 'Computer Engineering'
  }]
})

register(GET_JOB_BY_ID, service, mock)
