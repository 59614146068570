import { UPLOAD_COMPANY_PROFILE_SERVICE } from '../service/uploadCompanyProfilePicture'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'
const UPDATE_COMPANY_PROFILE_PIC_STATE = 'UPDATE_COMPANY_PROFILE_PIC_STATE'

export const updateCompanyProfilePicState = (values = {}) => ({
  type: UPDATE_COMPANY_PROFILE_PIC_STATE,
  profilePicState: values
})

const initialState = {
  show: false,
  isProfileAlreadyUploaded: false,
  src: '',
  imageState: '',
  isUploaded: false,
  imgType: '',
  imgName: '',
  disableSave: true,
  profileUrlIcon: false,
  isProfilePicUploaded: false,
  videoBase64String: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_COMPANY_PROFILE_PIC_STATE: return updateProfilePic(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(UPLOAD_COMPANY_PROFILE_SERVICE)(action)) return updateCompanyProfilePicInfo(state, action)
      if (isAsyncComplete(UPLOAD_COMPANY_PROFILE_SERVICE)(action)) return updateCompanyProfilePicInfoResult(state, action)
      if (isAsyncFailure(UPLOAD_COMPANY_PROFILE_SERVICE)(action)) return updateCompanyProfilePicInfoError(state, action)
      return state
    default:
      return state
  }
}

const updateProfilePic = (state, action) => {
  return {
    ...state,
    ...action.profilePicState,
    isProfilePicUploaded: false
  }
}

const updateCompanyProfilePicInfo = (state, action) => ({
  ...state,
  loading: true
})

const updateCompanyProfilePicInfoResult = (state, action) => {
  const profilePicture = action.response || {}
  const data = profilePicture.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      isProfilePicUploaded: true
    }
  }
}

const updateCompanyProfilePicInfoError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
