import { isEmpty } from 'lodash'

export const fieldNames = {
  companyName :'CompanyName',
  firstName: 'FirstName',
  lastName: 'LastName',
  email: 'EmailId',
  contactNumber: 'MobileNumber',
  alternateNumber: 'AlternateMobileNumber',
  city: 'LocationId',
  password: 'UserPassword',
  otp: 'OneTimePassword'
}

const required = v => v ? undefined : 'This is a required field'

const email = value =>
  isEmpty(value) || (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
    ? undefined : 'Invalid email address'

const mobileNumber = value => value.length < 10 ? 'Invalid Mobile Number.' : undefined

const password = value => value.length > 0 && value.length < 6 ? 'Password is too short.' : undefined

export const basicInfoFields = [
  {
  name: 'candidate-name-field-group',
    formFieldGroup: true,
    fields: [{
    name: fieldNames.companyName,
    type: 'text',
    width: '100%',
    bsSize: 'lg',
    placeholder: 'Enter Company Name',
    required: true,
    validate: [required],
    mandatory: true
  }]
},
  {
    name: 'candidate-name-field-group',
    formFieldGroup: true,
    fields: [{
      name: fieldNames.firstName,
      type: 'text',
      width: '35%',
      bsSize: 'md',
      placeholder: 'Enter First Name',
      required: true,
      validate: [required],
      mandatory: true
    },
    {
      name: fieldNames.lastName,
      type: 'text',
      width: '35%',
      bsSize: 'md',
      placeholder: 'Enter Last Name',
      required: true,
      validate: [required],
      mandatory: true
    }]
  },
  {
    name: 'candidate-email-field-group',
    formFieldGroup: true,
    fields: [{
      name: fieldNames.email,
      type: 'text',
      width: '100%',
      placeholder: 'Enter Email address',
      required: true,
      validate: [required, email],
      mandatory: true
    }]
  },
  {
    name: 'candidate-contact-number-field-group',
    formFieldGroup: true,
    fields: [{
      name: fieldNames.contactNumber,
      type: 'number',
      width: '35%',
      bsSize: 'md',
      placeholder: 'Enter Contact Number',
      required: true,
      validate: [required, mobileNumber],
      mandatory: true
    },
    {
      name: fieldNames.alternateNumber,
      type: 'number',
      width: '35%',
      bsSize: 'md',
      placeholder: 'Enter Alternate Number'
    }]
  },
  {
    name: 'candidate-city-field-group',
    formFieldGroup: true,
    fields: [{
      name: fieldNames.city,
      type: 'selectdropdown',
      width: '100%',
      bsSize: 'md',
      placeholder: 'Company Location',
      optionList: 'cityList',
      required: true,
      showSearch: true,
      validate: [required],
      enableSearch: true,
      className: 'candidate-location',
      mandatory: true
    }]
  },
  {
    name: 'candidate-password-field-group',
    formFieldGroup: true,
    fields: [{
      name: fieldNames.password,
      type: 'password',
      bsSize: 'md',
      description: 'Password should be of minimum 6 characters/numbers',
      width: '100%',
      placeholder: 'Enter Password',
      required: true,
      showHideIcon: true,
      validate: [required, password],
      mandatory: true
    }]
  }
]

export const otpField = [
  {
    name: 'candidate-otp-field-group',
    formFieldGroup: true,
    fields: [{
      name: fieldNames.otp,
      type: 'number',
      width: '20%',
      bsSize: 'sm',
      placeholder: 'Enter OTP'
    }]
  }
]
