import { isNil, isEmpty } from 'lodash'

export const getValueLabelListForNumber = (from = 0, to = 99, firstValue = '') => {
  let list = []
  if (!isEmpty(firstValue) && !isNil(firstValue)) list.push(firstValue)
  for (let i = from; i <= to; i++) {
    list.push({ value: i, label: i.toString() })
  }
  return list
}

export const getValueLabelObject = (value, label = '') => {
  return { value: value, label: label }
}

export const getValueFromObject = (obj = {}) => {
  return !isNil(obj) && !isEmpty(obj) ? obj.value : 0
}
export const getLabelFromObject = (obj = {}) => {
  return !isNil(obj) && !isEmpty(obj) ? obj.label : ''
}
export const getCommaSeperatedLabel = (list = []) => {
  let label = ''
  list.map((item, i) => {
    label = i === 0 ? item.label : (label + ', ' + item.label)
  })
  return label
}

export const getYearListInfo = (fromYear = 1970, toYear = new Date().getFullYear()) => {
  let list = []
  for (let i = toYear; i >= fromYear; i--) {
    list.push({ value: i, label: i.toString() })
  }
  return list
}


export const getQualificationObjectList = (props) => {
  const l = {}
  if (props.candidateProfile.EducationDetailsList) {
    props.candidateProfile.EducationDetailsList.map((item, i) => {
      l['Qualification_' + (i + 1)] = {
        EducationId: item.educationId,
        QualificationId: item.qualificationId > 0 ? getValueLabelObject(item.qualificationId, item.qualificationName) : [],
        MarksType: item.marksType > 0 ? getValueLabelObject(item.marksType, props.marksTypeList[item.marksType - 1].label) : [],
        MarksObtained: item.marksObtained,
        SpecializationId: item.specializationId > 0 ? getValueLabelObject(item.specializationId, item.specializationName) : [],
        YearOfPassing: !isEmpty(item.yearOfPassing) ? getValueLabelObject(item.yearOfPassing, item.yearOfPassing) : [],
        UniversityId: item.universityId > 0 ? getValueLabelObject(item.universityId, item.universityName) : [],
        Other: item.other
      }
    })
  }
  return l
}
export const getImportCandidateQualificationObjectList = (props) => {
  const l = {}
  if (props.addCandidates.EducationDetailsList) {
    props.addCandidates.EducationDetailsList.map((item, i) => {
      l['Qualification_' + (i + 1)] = {
        EducationId: item.educationId,
        QualificationId: item.qualificationId > 0 ? getValueLabelObject(item.qualificationId, item.qualificationName) : [],
        MarksType: item.marksType > 0 ? getValueLabelObject(item.marksType, props.marksTypeList[item.marksType - 1].label) : [],
        MarksObtained: item.marksObtained,
        SpecializationId: item.specializationId > 0 ? getValueLabelObject(item.specializationId, item.specializationName) : [],
        YearOfPassing: !isEmpty(item.yearOfPassing) ? getValueLabelObject(item.yearOfPassing, item.yearOfPassing) : [],
        UniversityId: item.universityId > 0 ? getValueLabelObject(item.universityId, item.universityName) : [],
        Other: item.other
      }
    })
  }
  return l
}


export const getCertificationObjectList = (list) => {
  const l = {}
  if (list) {
    list.map((item, i) => {
      l['Certification_' + (i + 1)] = {
        CertificationName: item.certificationName,
        Description: item.description
      }
    })
  }
  return l
}

export const getPreviousExperienceObjectList = (list) => {
  const l = {}
  if (list) {
    list.map((item, i) => {
      l['PreviousExperience_' + (i + 1)] = {
        PreviousExperienceId: item.previousExperienceId,
        CompanyName: item.companyName,
        Designation: item.designation,
        DurationFrom: item.durationFrom,
        DurationTo: item.durationToTillDate ? new Date() : item.durationTo,
        DurationToTillDate: item.durationToTillDate
      }
    })
  }
  return l
}

export const getLanguageObjectList = (list) => {
  const l = {}
  if (list) {
    list.map((item, i) => {
      l['lang_' + (i + 1)] = {
        LanguageName: item.languageName,
        LanguageRead: item.languageRead,
        LanguageSpeak: item.languageSpeak,
        LanguageWrite: item.languageWrite
      }
    })
  }
  return l
}

export const getWorkExperienceObjectList = (list, employmentTypeList) => {
  const workExperienceObj = {}
  if (list) {
    list.map((item, i) => {
      let preferedLocation = []
      item.preferedLocationList.map((locationItem, index) => {
        preferedLocation.push({ value: locationItem.locationId, label: locationItem.locationName })
      })
      workExperienceObj['WorkExperience_' + (i + 1)] = {
        WorkExperienceId: item.workExperienceId,
        KeySkills: item.keySkills,
        IndustryTypeId: { value: item.industryTypeId, label: item.industryTypeName },
        FunctionalAreaId: { value: item.functionalAreaId, label: item.functionalAreaName },
        RoleId: { value: item.roleId, label: item.roleName },
        CurrentLocationId: { value: item.currentLocationId, label: item.currentLocationName },
        PreferedIndustryTypeList: !isEmpty(item.preferedIndustryTypeList) ? {
          value: item.preferedIndustryTypeList[0].industryId,
          label: item.preferedIndustryTypeList[0].industryName
        } : [],
        PreferedFunctionalAreaList: !isNil(item.preferedFunctionalAreaList) && !isEmpty(item.preferedFunctionalAreaList) && item.preferedFunctionalAreaList[0].functionalAreaId > 0 ? {
          value: item.preferedFunctionalAreaList[0].functionalAreaId,
          label: item.preferedFunctionalAreaList[0].functionalAreaName
        } : [],
        PreferedRoleList: !isNil(item.preferedRoleList) && !isEmpty(item.preferedRoleList) && item.preferedRoleList[0].roleId > 0 ? {
          value: item.preferedRoleList[0].roleId,
          label: item.preferedRoleList[0].roleName
        } : [],
        PreferedLocationList: preferedLocation,
        PreferedJobTypeId: { value: item.preferedJobTypeId, label: employmentTypeList[item.preferedJobTypeId - 1].label }
      }
    })
  }
  return workExperienceObj
}

export const getEmptyWorkExperienceObject = (candidateProfile) => {
  const workExperienceObj = {}

  workExperienceObj['WorkExperience_1'] = {
    WorkExperienceId: 0,
    KeySkills: '',
    IndustryTypeId: 0,
    FunctionalAreaId: 0,
    RoleId: 0,
    PreferedIndustryTypeList: 0,
    PreferedFunctionalAreaList: [],
    PreferedRoleList: [],
    CurrentLocationId: { value: candidateProfile.LocationId, label: candidateProfile.LocationName },
    PreferedLocationList: 0,
    PreferedJobTypeId: 0
  }
  return workExperienceObj
}

export const getEmptyImportCandidateQualificationObjectList = (props) => {
  const QualificationList = {}
  QualificationList['QualificationList']= {
    Qualification_1: {
      EducationId: 0,
      QualificationId: 0,
      MarksType: 0,
      MarksObtained: 0,
      SpecializationId: 0,
      YearOfPassing: '',
      UniversityId: 0,
      Other: ''
    },
    Qualification_2: {
      EducationId: 0,
      QualificationId: 0,
      MarksType: 0,
      MarksObtained: 0,
      SpecializationId: 0,
      YearOfPassing: '',
      UniversityId: 0,
      Other: ''
    }
  }
  return QualificationList
}

export const playVoiceSupportSpeech = (speechText = '', language = 'UK English Female', pitch = 1, utterence = 0.8) => {
  window.responsiveVoice.speak(speechText, language, {
    pitch: pitch
  }, {
    rate: utterence
  })
}


