const UPDATE_PROJECT_DETAILS_URL = 'UPDATE_PROJECT_DETAILS_URL'
const CLEAR_PROJECT_DETAIL = 'CLEAR_PROJECT_DETAIL'

export const updateProjectDetailsURLState = (values = {}, documentType) => ({
  type: UPDATE_PROJECT_DETAILS_URL,
  projectDetailsState: values,
  documentType
})

export const clearProjectDetails = (values = {}, documentType) => ({
  type: CLEAR_PROJECT_DETAIL
})

const initialState = {
  show: false,
  isProfileAlreadyUploaded: false,
  src: '',
  imageState: '',
  isUploaded: false,
  imgType: '',
  imageUrl: '',
  videoUrl: '',
  otherUrl: '',
  imageSize: 0,
  videoSize: 0,
  otherSize: 0,
  videoBase64String: '',
  imageBase64String: '',
  fileBase64String: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_PROJECT_DETAILS_URL: return updateProjectDetailsURLStateInfo(state, action)
    case CLEAR_PROJECT_DETAIL: return clearProjectDetailsState(state, action)
    default:
      return state
  }
}

const updateProjectDetailsURLStateInfo = (state, action) => {
  return {
    ...state,
    ...action.projectDetailsState
  }
}

const clearProjectDetailsState = (state, action) => {
  return {
    ...initialState
  }
}
