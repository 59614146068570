import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const GET_COUPON = 'GET_COUPON'

export const service = async (data) => {
  const url = BASE_API_URL + `/v1/jobtravia/coupon/` + data.CouponCode + `/` + data.EmailId
  const options = {
    ...getDefaultOptions(),
    method: 'GET'
  }
  const response = await window.fetch(url, options)
  const coupon = await deserializeJsonResponse(response)
  throwExceptionErrors(coupon)
  return coupon
}

const mock = request => Promise.resolve({
  id: 1,
  data: [{
    couponId: 1,
    couponCode: '15AUG',
    couponDiscount: 20,
    validDays: 0
  }]
})

register(GET_COUPON, service, mock)
