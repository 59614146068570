import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const REGISTER_CANDIDATE = 'REGISTER_CANDIDATE'

export const service = async (data) => {
  const url = BASE_API_URL + `/v1/jobtravia/candidateprofile/registercandidate`
  const options = {
    ...getDefaultOptions(),
    method: 'PATCH',
    body: JSON.stringify(data)
  }
  const response = await window.fetch(url, options)
  const register = await deserializeJsonResponse(response)
  throwExceptionErrors(register)
  return register
}

const mock = request => Promise.resolve({
  id: 1,
  data: [true]
})

register(REGISTER_CANDIDATE, service, mock)
