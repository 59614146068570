
import { compose,withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Footer from './component'
import { LINKEDIN_URL, FACEBOOK_URL, INSTAGRAM_URL, YOUTUBE_URL, TWITTER_URL } from './constants'

const mapStateToProps = state => ({ })

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

const handler = {
  onTermsAndConditionClick : props => () => {
    props.history.push('/termsandcondition')
  },
  onPrivacyPolicyClick : props => () => {
    props.history.push('/privacypolicy')
  }
}
const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps), 
  withHandlers(handler)
)

export default enhance(Footer)
