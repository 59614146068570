import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const GET_SPECIALIZATION_FOR_ADD_JOB = 'GET_SPECIALIZATION_FOR_ADD_JOB'

export const service = async (list) => {
  const url = BASE_API_URL + `/v1/jobtravia/specialization/getpreferedspecializationlist/` + list
  const options = {
    ...getDefaultOptions(),
    method: 'GET'
  }
  const response = await window.fetch(url, options)
  const specialization = await deserializeJsonResponse(response)
  throwExceptionErrors(specialization)
  return specialization
}

const mock = request => Promise.resolve({
  id: 1,
  data: [
    { name: 'Specialization1', id: 1 },
    { name: 'Specialization2', id: 2 },
    { name: 'Specialization3', id: 3 },
    { name: 'Specialization4', id: 4 },
    { name: 'Specialization5', id: 5 }]
})

register(GET_SPECIALIZATION_FOR_ADD_JOB, service, mock)
