import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const FORGOTPASSWORD = 'FORGOTPASSWORD'

const url = BASE_API_URL + `/v1/jobtravia/user/forgotpassword`
export const service = async (data) => {
  const options = {
    ...getDefaultOptions(),
    method: 'POST',
    body: JSON.stringify(data)
  }
  const response = await window.fetch(url, options)
  const forgetPassword = await deserializeJsonResponse(response)
  throwExceptionErrors(forgetPassword)
  return forgetPassword
}

const mock = request => Promise.resolve({
  id: 1,
  data: [{
    alternateMobileNumber: '9028612998',
    candidateId: 23,
    emailId: 'mandar.daoo@officebox.co.in',
    firstName: 'Mandar',
    gender: 1,
    imageUrl: null,
    isActive: true,
    isDeleted: false,
    lastModifiedBy: 27,
    lastModifiedDate: '2019-06-25T21:49:58',
    lastName: 'Daoo',
    mobileNumber: '9028612998',
    userLogin: 'mandar.daoo@officebox.co.in',
    userName: 'Mandar Daoo',
    userPassword: '*84AAC12F54AB666ECFC2A83C676908C8BBC381B1'
  }]
})

register(FORGOTPASSWORD, service, mock)
