import React from 'react'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { SelectDropdown, Link } from 'bizsense-components'
import { PROFILE_MENU_LIST, COMPANY_MENU_LIST } from './constants'
import { isNil } from 'lodash'
const UserRole = 1

const MobileHeader = props =>
  <div id='header-container-id' className='header-container'>
    {(window.location.href.indexOf('/register') < 0 && window.location.href.indexOf('/termsandcondition') < 0 && window.location.href.indexOf('/privacypolicy') < 0 &&
      window.location.href.indexOf('/login') < 0 && window.location.href.indexOf('/welcome') < 0 &&
      window.location.href.indexOf('/forgotPassword') < 0 &&
      window.location.href.indexOf('/candidateProfile') < 0 && window.location.href.indexOf('/settings') < 0 &&
      window.location.href.indexOf('/myservices') < 0 && window.location.href.indexOf('/jobsearch') < 0 &&
      window.location.href.indexOf('/invitefriends') < 0 && window.location.href.indexOf('/jobs') < 0 &&
      window.location.href.indexOf('/appliedjobs') < 0 && window.location.href.indexOf('/jobpost') < 0 &&
      window.location.href.indexOf('/interviewSkill') < 0 && window.location.href.indexOf('/psychomatricTest') < 0 &&
      window.location.href.indexOf('/groupDiscussion') < 0 && window.location.href.indexOf('/resumeAssessment') < 0 &&
      window.location.href.indexOf('/aptitudeTest') < 0 && window.location.href.indexOf('/transactionSummary') < 0 &&
      window.location.href.indexOf('/companywelcome') < 0 && window.location.href.indexOf('/companyProfile') < 0 &&
      window.location.href.indexOf('/companysettings') < 0 && window.location.href.indexOf('/candidatesearch') < 0 &&
      window.location.href.indexOf('/companymyservices') < 0 && window.location.href.indexOf('/manageuser') < 0 &&
      window.location.href.indexOf('/addcandidates') < 0 && window.location.href.indexOf('/addedcandidatelist') < 0) &&
      (<div id='mobile-header-sidebar-id' className='mobile-header-sidebar'>
        <FontAwesomeIcon icon={faBars} onClick={props.openSidebar} />
      </div>)
    }
    <div id='mobile-header-logo-id' className='mobile-header-logo'>
      <img id='logo-mobile-id' className='logo-mobile' onClick={props.onLogoClick} src={require('images/logo.svg')} alt='JabTravia' />
    </div>
    { (window.location.href.indexOf('/welcome') > 0 || window.location.href.indexOf('/companywelcome') > 0 ||
      window.location.href.indexOf('/candidateProfile') > 0 || window.location.href.indexOf('/settings') > 0 ||
      window.location.href.indexOf('/companyProfile') > 0 || window.location.href.indexOf('/companysettings') > 0 ||
      window.location.href.indexOf('/myservices') > 0 || window.location.href.indexOf('/jobsearch') > 0 ||
      window.location.href.indexOf('/invitefriends') > 0 || window.location.href.indexOf('/jobs') > 0 ||
      window.location.href.indexOf('/appliedjobs') > 0 || window.location.href.indexOf('/jobpost') > 0 ||
      window.location.href.indexOf('/jobapplications') > 0 || window.location.href.indexOf('/transactionSummary') > 0 ||
      window.location.href.indexOf('/activecandidatelist') > 0 || window.location.href.indexOf('/candidatesearch') > 0 ||
      window.location.href.indexOf('/manageuser') > 0 || window.location.href.indexOf('/companymyservices') > 0 ||
      window.location.href.indexOf('/addcandidates') > 0 || window.location.href.indexOf('/addedcandidatelist') > 0 ||
      window.location.href.indexOf('/activecompanylist') > 0) &&
      <div id='profile-menu-section-id' className='profile-menu-section'>
        <SelectDropdown
          bsSize='sm'
          placeholder={props.header.FirstName}
          name='ProfileMenuId'
          options={!isNil(props.header) && props.header.UserRole === UserRole ? PROFILE_MENU_LIST : COMPANY_MENU_LIST}
          onChange={props.onProfileMenuClick}
          className='header-dropdown'
          value=''
        />
      </div>}
    <div id='mySidepanel' className={props.sidebarOpenClose ? 'sidepanel-open' : 'sidepanel-close'}>
      <a href='javascript:void(0)' id='close-sidebar-id' className='closebtn' onClick={props.closeSidebar} >×</a>
      <Link id='about-us-id' onClick={props.onAboutUsClick} >About Us</Link>
      <Link id='services-id' onClick={props.onServicesClick} >Services</Link>
      <Link id='contact-us-id' onClick={props.onContactUsClick}>Contact Us</Link>
      <Link id='register-id' onClick={props.onRegisterClick}>Register</Link>
      <Link id='login-id' onClick={props.onLoginClick}>Login</Link>
    </div>
  </div>

export default MobileHeader
