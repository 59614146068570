import React from 'react'
import Header from './../../Header/components/index'
import Footer from './../../Footer/components/index'
import './index.scss'

const App = props =>
  <div>
    <Header />
    <div className='page-component'>
      {props.children}
    </div>
    <Footer />
  </div>
export default App
