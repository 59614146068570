export const SET_ERROR_MESSAGE_FOR_UPLOAD_RESUME = 'SET_ERROR_MESSAGE_FOR_UPLOAD_RESUME'
export const CLEAR_ERROR_MESSAGE_FOR_UPLOAD_RESUME = 'CLEAR_ERROR_MESSAGE_FOR_UPLOAD_RESUME'

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE_FOR_UPLOAD_RESUME,
  message
})

export const clearErrorMessage = (message) => ({
  type: CLEAR_ERROR_MESSAGE_FOR_UPLOAD_RESUME
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  UserId: 0,
  IsUploadResumeUpdated: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ERROR_MESSAGE_FOR_UPLOAD_RESUME: return setError(state, action)
    case CLEAR_ERROR_MESSAGE_FOR_UPLOAD_RESUME: return clearError(state, action)
    default: return state
  }
}

const setError = (state, action) => ({
  ...state,
  errors: [action.message]
})

const clearError = (state, action) => ({
  ...state,
  errors: []
})
