import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const GET_DESIGNATION = 'GET_DESIGNATION'

export const service = async () => {
  const url = BASE_API_URL + `/v1/jobtravia/designation`
  const options = {
    ...getDefaultOptions(),
    method: 'GET'
  }
  const response = await window.fetch(url, options)
  const designation = await deserializeJsonResponse(response)
  throwExceptionErrors(designation)
  return designation
}

const mock = request => Promise.resolve({
  id: 1,
  data: [
    { name: 'Designation1', id: 1 },
    { name: 'Designation2', id: 2 },
    { name: 'Designation3', id: 3 },
    { name: 'Designation4', id: 4 },
    { name: 'Designation5', id: 5 }]
})

register(GET_DESIGNATION, service, mock)
