import { ADD_CANDIDATE } from '../services/addCandidates'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'
import {GET_IMPORTED_CANDIDATE_FOR_UPDATE } from '../services/getImportCandidateForUpdate'

export const ADD_CANDIDATE_LANGUAGE_KNOWN = 'ADD_CANDIDATE_LANGUAGE_KNOWN'
export const SET_ADD_CANDIDATE_ERROR_MESSAGE = 'SET_ADD_CANDIDATE_ERROR_MESSAGE'
export const CLEAR_ADD_CANDIDATE_ERROR_MESSAGE = 'CLEAR_ADD_CANDIDATE_ERROR_MESSAGE'
export const SET_ADD_CANDIDATE_PROJECT_ERROR_MESSAGE = 'SET_ADD_CANDIDATE_PROJECT_ERROR_MESSAGE'
export const CLEAR_CANDIDATE_IMPORT_DETAILS ='CLEAR_CANDIDATE_IMPORT_DETAILS'

const UPDATE_ADD_CANDIDATE_PROJECT_DETAILS_URL = 'UPDATE_ADD_CANDIDATE_PROJECT_DETAILS_URL'
const CLEAR_ADD_CANDIDATE_PROJECT_DETAIL = 'CLEAR_ADD_CANDIDATE_PROJECT_DETAIL'
const UPLOAD_CANDIDATE_RESUME = 'UPLOAD_CANDIDATE_RESUME'

export const updateCandidateProjectDetailsURLState = (values = {}, documentType) => ({
  type: UPDATE_ADD_CANDIDATE_PROJECT_DETAILS_URL,
  projectDetailsState: values,
  documentType
})

export const updateCandidateResumeState = (values = {}, documentType) => ({
  type: UPLOAD_CANDIDATE_RESUME,
  uploadResumeState: values,
  documentType
})

export const clearProjectDetails = (values = {}, documentType) => ({
  type: CLEAR_ADD_CANDIDATE_PROJECT_DETAIL
})

export const addCandiadtesLanguageKnown = (list) => ({
  type: ADD_CANDIDATE_LANGUAGE_KNOWN,
  list
})

export const setAddCandidatesErrorMessage = (message) => ({
  type: SET_ADD_CANDIDATE_ERROR_MESSAGE,
  message
})

export const setAddCandidatesProjectErrorMessage = (message) => ({
  type: SET_ADD_CANDIDATE_PROJECT_ERROR_MESSAGE,
  message
})


export const clearAddCandidatesErrorMessage = (message) => ({
  type: CLEAR_ADD_CANDIDATE_ERROR_MESSAGE
})

const initialError = {
  code: '',
  message: '',
  values: []
}

export const initialLanguageKnownList = {
  LanguageName: '',
  LanguageRead: false,
  LanguageWrite: false,
  LanguageSpeak: false
}
export const initialPreviousExperienceList = {
  CompanyName: '',
  Designation: '',
  DurationFrom: 0,
  DurationTo: 0
}

const initialImportedCandiadteProfileState = {
  loading: false,
  errors: initialError,
  show: false,
  imageState: '',
  isUploaded: false,
  imgType: '',
  isProfileAlreadyUploaded: false,
  DesignationList: [],
  EmployerList: [],
  candidateSearchList: [],
  sortOrder: {},
  TotalCandidateSearchCount: 0,
  Src: '',
  OtherUrl: '',
  ImageSize: 0,
  VideoSize: 0,
  OtherSize: 0,
  ResumeType: '',
  EmailId: '',
  CandidateId: 0,
  OneTimePassword: '',
  CurrentEmailId: '',
  ConfirmEmailId: '',
  MobileNumber: '',
  AlternateMobileNumber: '',
  CandidateStatus: 1,
  FirstName: '',
  LastName: '',
  FullName: '',
  UserPassword: '',
  ImportedCandidateUserId: 0,
  ImageUrl: '',
  VideoUrl: '',
  LocationId: 0,
  LocationName: '',
  LanguageSupport: '',
  DateOfBirth: new Date(),
  UserCreatedDate: new Date(),
  LastModifiedDate: new Date(),
  Gender: '',
  MaritalStatus: '',
  IsHandicaped: '',
  IsEmailChanged: false,
  IsOtpGenerated: false,
  FunctionMode: '',
  CandidateType: 0,
  LanguageKnownList: [],
  CertificationDetailsList: [],
  EducationDetailsList: [],
  ProjectDetails: {},
  VideoBase64String: '',
  ImageBase64String: '',
  ProfileBase64String: '',
  FileBase64String: '',
  WorkExperienceList: [],
  EmploymentId: 0,
  CompanyName: '',
  Designation: '',
  YearsOfExperience: '',
  EmploymentDurationFrom: new Date(),
  EmploymentDurationTo: new Date(),
  EmploymentDurationToTillDate: false,
  Salary: 0,
  NoticePeriodId: 0,
  PreviousExperienceList: [],
  ResumeBase64String: '',
  ResumeUrl: '',
  ResumeSize: 0,
  RewardPoints: 0,
  SubscriptionPlanId: 0,
  SubscriptionAmount: 0,
  SubscriptionDays: 0,
  UserRole: 0,
  CompanyId: 0,
  JobPostCompanyName: '',
  IsCandidateImported: false,
  IsActive:false
}

export default function (state = initialImportedCandiadteProfileState, action) {
  switch (action.type) {
    case ADD_CANDIDATE_LANGUAGE_KNOWN: return languageKnownInfo(state, action)
    case SET_ADD_CANDIDATE_ERROR_MESSAGE: return setError(state, action)
    case SET_ADD_CANDIDATE_PROJECT_ERROR_MESSAGE:return setProjectError(state,action)
    case CLEAR_ADD_CANDIDATE_ERROR_MESSAGE: return clearError(state, action)
    case UPDATE_ADD_CANDIDATE_PROJECT_DETAILS_URL: return updateCandidateProjectDetailsURLStateInfo(state, action)
    case CLEAR_ADD_CANDIDATE_PROJECT_DETAIL: return clearProjectDetailsState(state, action)
    case UPLOAD_CANDIDATE_RESUME: return updateResumeStateInfo(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(ADD_CANDIDATE)(action)) return addCandidateDetails(state, action)
      if (isAsyncComplete(ADD_CANDIDATE)(action)) return addCandidateDetailsResult(state, action)
      if (isAsyncFailure(ADD_CANDIDATE)(action)) return addCandidateDetailsError(state, action)

      if (isAsyncOperation(GET_IMPORTED_CANDIDATE_FOR_UPDATE)(action)) return getImportedCandidateProfile(state, action)
      if (isAsyncComplete(GET_IMPORTED_CANDIDATE_FOR_UPDATE)(action)) return getImportedCandidateProfileResult(state, action)
      if (isAsyncFailure(GET_IMPORTED_CANDIDATE_FOR_UPDATE)(action)) return getImportedCandidateProfileError(state, action)

      return state
    default: return state
  }
}

const mapItem = item => ({
  ...initialLanguageKnownList,
  LanguageName: '',
  LanguageRead: false,
  LanguageWrite: false,
  LanguageSpeak: false
})

const setError = (state, action) => ({
  ...state,
  errors: [action.message]
})

const setProjectError = (state, action) => ({
  ...state,
  setProjectError: [action.message]
})

const clearError = (state, action) => ({
  ...state,
  errors: [],
  setProjectError:[],
  IsCandidateImported:false
})

const languageKnownInfo = (state, action) => ({
  ...state,
  errors: initialError,
  LanguageKnownList: action.list.map(mapItem)
})

const addCandidateDetails = (state, action) => ({
  ...initialImportedCandiadteProfileState,
  loading: true
})

const addCandidateDetailsResult = (state, action) => {
  const addCandidates = action.response || {}
  const data = addCandidates.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: ['Candidate Added Successfully.'],
      IsCandidateImported: true
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: addCandidates.errors, 
      IsCandidateImported: false
    }
  }
}

const addCandidateDetailsError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const updateCandidateProjectDetailsURLStateInfo = (state, action) => {
  return {
    ...state,
    ...action.projectDetailsState
  }
}

const clearProjectDetailsState = (state, action) => {
  return {
    ...initialImportedCandiadteProfileState
  }
}
const updateResumeStateInfo = (state, action) => {
  return {
    ...state,
    ...action.uploadResumeState
  }
}

const getImportedCandidateProfile = (state, action) => ({
  ...state,
  loading: true
})

const getImportedCandidateProfileResult = (state, action) => {
  const candidate = action.response || {}
  const data = candidate.data
  if (!isNil(data)) {
    const candidateProfileModal = data[0]
    return {
      ...state,
      loading: false,      
      IsEmailChanged: false,
      ImportedCandidateUserId: candidateProfileModal.userId,
      CandidateId: candidateProfileModal.candidateId,
      EmailId: candidateProfileModal.emailId,
      CurrentEmailId: candidateProfileModal.emailId,
      CandidateStatus: candidateProfileModal.candidateStatus,
      MobileNumber: candidateProfileModal.mobileNumber,
      AlternateMobileNumber: candidateProfileModal.alternateMobileNumber,
      FirstName: candidateProfileModal.firstName,
      LastName: candidateProfileModal.lastName,
      ImageUrl: !isNil(candidateProfileModal.candidateProjectDetails) ? candidateProfileModal.candidateProjectDetails.imageUrl : null,
      ImageSize: !isNil(candidateProfileModal.candidateProjectDetails) ? candidateProfileModal.candidateProjectDetails.imageSize :0,
      VideoUrl: !isNil(candidateProfileModal.candidateProjectDetails) ? candidateProfileModal.candidateProjectDetails.videoUrl : null,
      VideoSize: !isNil(candidateProfileModal.candidateProjectDetails) ? candidateProfileModal.candidateProjectDetails.videoSize :0,
      OtherUrl: !isNil(candidateProfileModal.candidateProjectDetails) ? candidateProfileModal.candidateProjectDetails.otherUrl : null,
      OtherSize: !isNil(candidateProfileModal.candidateProjectDetails) ? candidateProfileModal.candidateProjectDetails.otherSize : 0,      
      UserPassword: candidateProfileModal.userPassword,
      LocationId: candidateProfileModal.locationId,
      LocationName: candidateProfileModal.locationName,
      LanguageSupport: candidateProfileModal.languageSupport,
      Gender: candidateProfileModal.gender,
      DateOfBirth: candidateProfileModal.dateOfBirth,
      MaritalStatus: candidateProfileModal.maritalStatus,
      IsHandicaped: candidateProfileModal.isHandicaped,
      CandidateType: candidateProfileModal.candidateType,
      LanguageKnownList: candidateProfileModal.languageKnownList,
      CertificationDetailsList: candidateProfileModal.certificationDetailsList,
      EducationDetailsList: candidateProfileModal.educationDetailsList,
      ProjectDetails: candidateProfileModal.candidateProjectDetails,
      VideoBase64String: candidateProfileModal.videoBase64String,
      ImageBase64String: candidateProfileModal.imageBase64String,
      ProfileBase64String: candidateProfileModal.profileBase64String,
      FileBase64String: candidateProfileModal.otherBase64String,
      WorkExperienceList: candidateProfileModal.workExperienceList,
      EmploymentId: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.employmentId : 0,
      CompanyName: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.companyName : '',
      Designation: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.designation : '',
      YearsOfExperience: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.yearsOfExperience : '',
      EmploymentDurationFrom: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.employmentDurationFrom : '',
      EmploymentDurationTo: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.employmentDurationTo : '',
      EmploymentDurationToTillDate: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.employmentDurationToTillDate : '',
      SalaryInLacs: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.salaryInLacs : '',
      SalaryInThousands: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.salaryInThousands : '',
      NoticePeriodId: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.noticePeriodId : 0,
      MultimediaId: candidateProfileModal.resumeMultimediaId,
      ProjectMultimediaId : !isNil(candidateProfileModal.candidateProjectDetails) ? candidateProfileModal.candidateProjectDetails.multimediaId :0,
      PreviousExperienceList: candidateProfileModal.previousExperienceList,
      ResumeBase64String: candidateProfileModal.resumeBase64String,
      ResumeUrl: candidateProfileModal.resumeUrl,
      ResumeSize: candidateProfileModal.resumeSize,
      RewardPoints: candidateProfileModal.rewardPoints,
      SubscriptionPlanId: candidateProfileModal.subscriptionPlanId,
      SubscriptionAmount: candidateProfileModal.amount,
      SubscriptionDays: candidateProfileModal.noOfDays,
      ReferByUserId: candidateProfileModal.referByUserId,
      FullName: candidateProfileModal.firstName + ' ' + (!isNil(candidateProfileModal.lastName) ? candidateProfileModal.lastName : ''),
      UserRole: candidateProfileModal.userRole,
      CompanyId: candidateProfileModal.companyId,
      JobPostCompanyName: candidateProfileModal.companyName,
      UserCreatedDate: candidateProfileModal.userCreatedDate,
      LastModifiedDate: candidateProfileModal.lastModifiedDate,
      IsActive: candidateProfileModal.isActive
    }
  } else {
    return {
      ...initialImportedCandiadteProfileState
    }
  }
}
const getImportedCandidateProfileError = (state, action) => ({
  ...state,
  loading: false
})
