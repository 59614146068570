import { GET_SUB_USER_LIST } from '../services/getSubUserList'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isEmpty, isNil } from 'lodash'
import { convertDate } from 'utils/dateUtils'

const CLEAR_SUCCESS_MESSAGE_INFO = 'CLEAR_SUCCESS_MESSAGE_INFO'
const CLEAR_SUB_USER_LIST = 'CLEAR_SUB_USER_LIST'
export const SORT_SUB_USER_LIST = 'SORT_SUB_USER_LIST'
export const SET_ERROR_MESSAGE_SUB_USER_LIST = 'SET_ERROR_MESSAGE_SUB_USER_LIST'

const initialError = {
  code: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  FirstName: '',
  LastName: '',
  EmailId: '',
  Location: '',
  CompanyId: 0,
  UserId: sessionStorage.getItem('UserId'),
  CompanySubUser: 4,
  MobileNumber: 9999999999,
  UserPassword: 'User@123',
  CompanyName: '',
  sortOrder: {},
  TotalSubUserCount:0,
  list: [],
  subUserErrors: initialError,
}

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE_SUB_USER_LIST,
  message
})
export const clearSubUserList = () => ({
  type: CLEAR_SUB_USER_LIST
})
export const sortSubUserList = (colName, currentColOrder, dataType) => ({
  type: SORT_SUB_USER_LIST,
  colName,
  currentColOrder,
  dataType
})
export const clearSuccessMessage = () => ({
  type: CLEAR_SUCCESS_MESSAGE_INFO
})

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_SUCCESS_MESSAGE_INFO: return clearSuccessMessageInfo(state, action)
    case CLEAR_SUB_USER_LIST: return clearSubUserInfoList(state, action)
    case SORT_SUB_USER_LIST: return sortSubUserListInfo(state, action)
    case SET_ERROR_MESSAGE_SUB_USER_LIST : return setError(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_SUB_USER_LIST)(action)) return getSubUserList(state, action)
      if (isAsyncComplete(GET_SUB_USER_LIST)(action)) return getSubUserListResult(state, action)
      if (isAsyncFailure(GET_SUB_USER_LIST)(action)) return getSubUserListError(state, action)
    default: return state
  }
}
const clearSuccessMessageInfo = (state, action) => ({
  ...initialState
})

const sortSubUser = (colName, colOrder, dataType) => (a, b) => {
  const aValue = isNil(a[colName]) ? '' : dataType !== 'number' ? a[colName].toLowerCase() : a[colName]
  const bValue = isNil(b[colName]) ? '' : dataType !== 'number' ? b[colName].toLowerCase() : b[colName]
  if (colOrder === 'desc') {
    if (aValue > bValue) return -1
    if (aValue < bValue) return 1
    return 0
  }
  if (aValue < bValue) return -1
  if (aValue > bValue) return 1
  return 0
}
export const sortSubUserListInfo = (state, action) => {
  const currentSortOrder = action.currentColOrder === 'asc' ? 'desc' : 'asc'
  return {
    ...state,
    list: [...state.list].sort(sortSubUser(action.colName, currentSortOrder, action.dataType)),
    sortOrder: { colName: action.colName, currentColOrder: currentSortOrder }
  }
}
const setError = (state, action) => ({
  ...state,
  errors: [action.message]
})
const getSubUserList = (state, action) => ({
  ...state,
  loading: true
})

const clearSubUserInfoList = (state, action) => ({
  ...initialState
})

const mapItem = item => ({
  ...initialState,
  FirstName: item.firstName,
  LastName: item.lastName,
  EmailId: item.emailId,  
  LocationName: item.locationName,
  UserCreatedDate: convertDate(item.userCreatedDate,'DD-MM-YYYY'),
  LastModifiedDate: convertDate(item.lastModifiedDate,'DD-MM-YYYY'),
  EnableDisable: item.enableDisable,
  Delete: 'Delete',
  SubUserId:item.subUserId  
})

const getSubUserListResult = (state, action) => {
  const SubUser = action.response || {}
  const data = SubUser.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      subUserErrors: [data[1] + ' Records Found.'],
      list: data[0].map(mapItem),
      TotalSubUserCount: data[1]
    }
  } else {
    return {
      ...state,
      loading: false,
      subUserErrors: SubUser.errors,
      list: []
    }
  }
}

const getSubUserListError = (state, action) => ({
  ...state,
  loading: false,
  subUserErrors: action.errors
})