import { GET_DESIGNATION } from '../services/getDesignation'
import { GET_EMPLOYER } from '../services/getEmployer'
import { GET_CANDIDATE_SEARCH_LIST } from '../services/getCandidateList'
import { GET_CANDIDATE_PROFILE_MODAL } from '../services/getCandidateProfileModal'

import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'

const CLEAR_SEARCH_CANDIDATE_LIST = 'CLEAR_ACTIVE_CANDIDATE_LIST'
export const SORT_SEARCH_CANDIDATE_LIST = 'SORT_ACTIVE_CANDIDATE_LIST'
export const SET_ERROR_MESSAGE_SEARCH_CANDIDATE_LIST = 'SET_ERROR_MESSAGE_ACTIVE_CANDIDATE_LIST'

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialItem = {
  value: 0,
  label: ''
}
const initialSearchCandidateItem = {
  candidateSearchList: [],
  loading: false,
  loadingAddEdit: false,
  errors: initialError,
  sortOrder: {}  
}
const initialState = {
  loading: false,
  errors: initialError,
  DesignationList: [],
  EmployerList: [],
  candidateSearchList: [],
  sortOrder: {},
  totalCandidateSearchCount: 0,
  EmailId: '',
  CandidateId: 0,
  OneTimePassword: '',
  CurrentEmailId: '',
  ConfirmEmailId: '',
  MobileNumber: '',
  AlternateMobileNumber: '',
  CandidateStatus: 1,
  FirstName: '',
  LastName: '',
  FullName: '',
  UserPassword: '',
  UserId: 0,
  ImageUrl: '',
  VideoUrl: '',
  LocationId: 0,
  LocationName: '',
  LanguageSupport: '',
  DateOfBirth: new Date(),
  UserCreatedDate: new Date(),
  LastModifiedDate: new Date(),
  Gender: '',
  MaritalStatus: '',
  IsHandicaped: '',
  IsEmailChanged: false,
  IsOtpGenerated: false,
  FunctionMode: '',
  CandidateType: 0,
  LanguageKnownList: [],
  CertificationDetailsList: [],
  EducationDetailsList: [],
  ProjectDetails: {},
  VideoBase64String: '',
  ImageBase64String: '',
  ProfileBase64String: '',
  FileBase64String: '',
  WorkExperienceList: [],
  EmploymentId: 0,
  CompanyName: '',
  Designation: '',
  YearsOfExperience: '',
  EmploymentDurationFrom: new Date(),
  EmploymentDurationTo: new Date(),
  EmploymentDurationToTillDate: false,
  Salary: 0,
  NoticePeriodId: 0,
  PreviousExperienceList: [],
  ResumeBase64String: '',
  ResumeUrl: '',
  ResumeSize: 0,
  RewardPoints: 0,
  SubscriptionPlanId: 0,
  SubscriptionAmount: 0,
  SubscriptionDays: 0,
  UserRole: 0,
  CompanyId: 0,
  JobPostCompanyName: ''
}
export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE_SEARCH_CANDIDATE_LIST,
  message
})
export const clearSearchCandidateList = () => ({
  type: CLEAR_SEARCH_CANDIDATE_LIST
})
export const sortSearchCandidateList = (colName, currentColOrder, dataType) => ({
  type: SORT_SEARCH_CANDIDATE_LIST,
  colName,
  currentColOrder,
  dataType
})

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_SEARCH_CANDIDATE_LIST: return clearSearchCandidateInfoList(state, action)
    case SORT_SEARCH_CANDIDATE_LIST: return sortSearchCandidateListInfo(state, action)
    case SET_ERROR_MESSAGE_SEARCH_CANDIDATE_LIST : return setError(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_DESIGNATION)(action)) return getDesignation(state, action)
      if (isAsyncComplete(GET_DESIGNATION)(action)) return getDesignationResult(state, action)
      if (isAsyncFailure(GET_DESIGNATION)(action)) return getDesignationError(state, action)

      if (isAsyncOperation(GET_EMPLOYER)(action)) return getEmployer(state, action)
      if (isAsyncComplete(GET_EMPLOYER)(action)) return getEmployerResult(state, action)
      if (isAsyncFailure(GET_EMPLOYER)(action)) return getEmployerError(state, action)

      if (isAsyncOperation(GET_CANDIDATE_SEARCH_LIST)(action)) return getCandidateSearchList(state, action)
      if (isAsyncComplete(GET_CANDIDATE_SEARCH_LIST)(action)) return getCandidateSearchResult(state, action)
      if (isAsyncFailure(GET_CANDIDATE_SEARCH_LIST)(action)) return getCandidateSearchError(state, action)

      if (isAsyncOperation(GET_CANDIDATE_PROFILE_MODAL)(action)) return getCandidateProfile(state, action)
      if (isAsyncComplete(GET_CANDIDATE_PROFILE_MODAL)(action)) return getCandidateProfileResult(state, action)
      if (isAsyncFailure(GET_CANDIDATE_PROFILE_MODAL)(action)) return getCandidateProfileError(state, action)

      return state
    default: return state
  }
}
const sortSearchCandidate = (colName, colOrder, dataType) => (a, b) => {
  const aValue = isNil(a[colName]) ? '' : dataType !== 'number' ? a[colName].toLowerCase() : a[colName]
  const bValue = isNil(b[colName]) ? '' : dataType !== 'number' ? b[colName].toLowerCase() : b[colName]
  if (colOrder === 'desc') {
    if (aValue > bValue) return -1
    if (aValue < bValue) return 1
    return 0
  }
  if (aValue < bValue) return -1
  if (aValue > bValue) return 1
  return 0
}
export const sortSearchCandidateListInfo = (state, action) => {
  const currentSortOrder = action.currentColOrder === 'asc' ? 'desc' : 'asc'
  return {
    ...state,
    candidateSearchList: [...state.candidateSearchList].sort(sortSearchCandidate(action.colName, currentSortOrder, action.dataType)),
    sortOrder: { colName: action.colName, currentColOrder: currentSortOrder }
  }
}
const setError = (state, action) => ({
  ...state,
  errors: [action.message],
  candidateSearchList: []
})
const clearSearchCandidateInfoList = (state, action) => ({
  ...initialSearchCandidateItem
})

const mapItem = item => ({
  ...initialItem,
  value: item.id,
  label: item.name
})
const mapCandidtaeSearchItem = item => ({
  ...initialSearchCandidateItem,
  CandidateName: item.candidateName,
  YearsOfExperience: item.yearsOfExperience,
  CurrentSalary: item.currentSalary,
  LocationName: item.locationName,
  Designation: item.designation,
  CompanyName: item.companyName,
  HighestEducation: item.highestEducation,
  PreferredLocation: item.preferredLocation,
  KeySkills: item.keySkills,
  ImageBase64String: item.imageBase64String,
  UserId: item.userId,
  EmailId: item.emailId,
  MobileNumber: item.mobileNumber
})

const getDesignation = (state, action) => ({
  ...state,
  loading: true
})

const getDesignationResult = (state, action) => {
  const DesignationList = action.response || {}
  const data = DesignationList.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      DesignationList: data[0].map(mapItem)
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: initialError,
      DesignationList: []
    }
  }
}
const getDesignationError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const getEmployer = (state, action) => ({
  ...state,
  loading: true
})

const getEmployerResult = (state, action) => {
  const EmployerList = action.response || {}
  const data = EmployerList.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      EmployerList: data[0].map(mapItem)
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: initialError,
      EmployerList: []
    }
  }
}
const getEmployerError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const getCandidateSearchList = (state, action) => ({
  ...state,
  loading: true
})

const getCandidateSearchResult = (state, action) => {
  const candidateSearch = action.response || {}
  const data = candidateSearch.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: [data[1] + ' Candidate(s) Found.'],
      candidateSearchList: data[0].map(mapCandidtaeSearchItem),
      totalCandidateSearchCount: data[1]
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: candidateSearch.errors,
      candidateSearchList: [],
      totalCandidateSearchCount: 0
    }
  }
}
const getCandidateSearchError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const getCandidateProfile = (state, action) => ({
  ...state,
  loading: true
})

const getCandidateProfileResult = (state, action) => {
  const candidate = action.response || {}
  const data = candidate.data
  if (!isNil(data)) {
    const candidateProfileModal = data[0]
    return {
      ...state,
      loading: false,      
      IsEmailChanged: false,
      UserId: candidateProfileModal.userId,
      CandidateId: candidateProfileModal.candidateId,
      EmailId: candidateProfileModal.emailId,
      CurrentEmailId: candidateProfileModal.emailId,
      CandidateStatus: candidateProfileModal.candidateStatus,
      MobileNumber: candidateProfileModal.mobileNumber,
      AlternateMobileNumber: candidateProfileModal.alternateMobileNumber,
      FirstName: candidateProfileModal.firstName,
      LastName: candidateProfileModal.lastName,
      ImageUrl: candidateProfileModal.imageUrl,
      VideoUrl: candidateProfileModal.videoUrl,
      UserPassword: candidateProfileModal.userPassword,
      LocationId: candidateProfileModal.locationId,
      LocationName: candidateProfileModal.locationName,
      LanguageSupport: candidateProfileModal.languageSupport,
      Gender: candidateProfileModal.gender,
      DateOfBirth: candidateProfileModal.dateOfBirth,
      MaritalStatus: candidateProfileModal.maritalStatus,
      IsHandicaped: candidateProfileModal.isHandicaped,
      CandidateType: candidateProfileModal.candidateType,
      LanguageKnownList: candidateProfileModal.languageKnownList,
      CertificationDetailsList: candidateProfileModal.certificationDetailsList,
      EducationDetailsList: candidateProfileModal.educationDetailsList,
      ProjectDetails: candidateProfileModal.candidateProjectDetails,
      VideoBase64String: candidateProfileModal.videoBase64String,
      ImageBase64String: candidateProfileModal.imageBase64String,
      ProfileBase64String: candidateProfileModal.profileBase64String,
      FileBase64String: candidateProfileModal.otherBase64String,
      WorkExperienceList: candidateProfileModal.workExperienceList,
      EmploymentId: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.employmentId : 0,
      CompanyName: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.companyName : '',
      Designation: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.designation : '',
      YearsOfExperience: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.yearsOfExperience : '',
      EmploymentDurationFrom: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.employmentDurationFrom : '',
      EmploymentDurationTo: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.employmentDurationTo : '',
      EmploymentDurationToTillDate: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.employmentDurationToTillDate : '',
      SalaryInLacs: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.salaryInLacs : '',
      SalaryInThousands: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.salaryInThousands : '',
      NoticePeriodId: !isNil(candidateProfileModal.candidateEmploymentHistory) ? candidateProfileModal.candidateEmploymentHistory.noticePeriodId : 0,
      MultimediaId: candidateProfileModal.resumeMultimediaId,
      PreviousExperienceList: candidateProfileModal.previousExperienceList,
      ResumeBase64String: candidateProfileModal.resumeBase64String,
      ResumeUrl: candidateProfileModal.resumeUrl,
      ResumeSize: candidateProfileModal.resumeSize,
      DocumentType:candidateProfileModal.documentType,
      RewardPoints: candidateProfileModal.rewardPoints,
      SubscriptionPlanId: candidateProfileModal.subscriptionPlanId,
      SubscriptionAmount: candidateProfileModal.amount,
      SubscriptionDays: candidateProfileModal.noOfDays,
      ReferByUserId: candidateProfileModal.referByUserId,
      FullName: candidateProfileModal.firstName + ' ' + (!isNil(candidateProfileModal.lastName) ? candidateProfileModal.lastName : ''),
      UserRole: candidateProfileModal.userRole,
      CompanyId: candidateProfileModal.companyId,
      JobPostCompanyName: candidateProfileModal.companyName,
      UserCreatedDate: candidateProfileModal.userCreatedDate,
      LastModifiedDate : candidateProfileModal.lastModifiedDate
    }
  } else {
    return {
      ...initialState
    }
  }
}
const getCandidateProfileError = (state, action) => ({
  ...state,
  loading: false
})
