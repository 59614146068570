import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import feature from '../Feature/reducer'
import registerUser from '../RegisterUser/reducer'
import sendInquiry from '../Website/ContactUs/reducer'
import changePassword from '../ChangePassword/reducer'
import changeEmail from '../ChangeEmail/reducer'
import login from '../Login/reducer'
import forgotPassword from '../ForgotPassword/reducer'
import profileUpload from '../CandidateProfileModal/reducer'
import changeContactDetails from '../ChangeContactDetails/reducer'
import personalDetails from '../PersonalDetails/reducer'
import resetPassword from '../ResetPassword/reducer'
import candidateType from '../CandidateType/reducer'
import headerTab from '../HeaderTabSection/reducer'
import eduacationalDetails from '../EducationalDetails/reducer'
import candidateProgress from '../CandidateProfile/reducer'
import projectDetails from '../ProjectDetails/reducer'
import workExperience from '../WorkExperience/reducer'
import employmentHistory from '../EmploymentHistory/reducer'
import payments from '../MyServices/reducer'
import inviteFriends from '../InviteFriends/reducer'
import settings from '../Settings/reducer'
import jobPost from '../JobPost/reducer'
import addJobPost from '../AddJobPost/reducer'
import jobSearch from '../JobSearch/reducer'
import appliedJobs from '../AppliedJobs/reducer'
import jobApplications from '../JobApplications/reducer'
import transactionSummary from '../TransactionSummary/reducer'
import uploadResume from '../UploadResume/reducer'
import companyProfileDetails from '../CompanyProfileDetails/reducer'
import registerCompany from '../RegisterCompany/reducer'
import companyType from '../CompanyType/reducer'
import activeCandidateList from '../ActiveCandidateList/reducer'
import companyProfile from '../CompanyProfile/reducer'
import leadershipDetails from '../LeadershipDetails/reducer'
import companySettings from '../CompanySettings/reducer'
import changeCompanyContactDetails from '../ChangeCompanyDetails/reducer'
import companyProfileUpload from '../CompanyProfileModal/reducer'
import candidateSearch from '../CandidateSearch/reducer'
import companyDetails from '../CompanyDetails/reducer'
import companyPayments from '../CompanyMyServices/reducer'
import recruitmentDetails from '../RecruitmentDetails/reducer'
import changeCompanyEmail from '../ChangeCompanyEmail/reducer'
import addCandidates from '../AddCandidates/reducer'
import addedCandidateList from '../AddedCandidateList/reducer'
import activeCompanyList from '../ActiveCompanyList/reducer'
import subUserList from  '../ManageUsers/reducer'
  
const rootReducer = combineReducers({
  form: formReducer,
  registerUser,
  feature,
  sendInquiry,
  changePassword,
  changeEmail,
  login,
  forgotPassword,
  resetPassword,
  profileUpload,
  changeContactDetails,
  personalDetails,
  candidateType,
  headerTab,
  eduacationalDetails,
  candidateProgress,
  projectDetails,
  workExperience,
  employmentHistory,
  payments,
  inviteFriends,
  settings,
  jobPost,
  addJobPost,
  jobSearch,
  appliedJobs,
  jobApplications,
  transactionSummary,
  uploadResume,
  companyProfileDetails,
  registerCompany,
  activeCandidateList,
  companyType,
  companyProfile,
  leadershipDetails,
  companySettings,
  changeCompanyContactDetails,
  companyProfileUpload,
  candidateSearch,
  companyDetails,
  companyPayments,
  recruitmentDetails,
  changeCompanyEmail,
  addCandidates,
  addedCandidateList,
  activeCompanyList,
  subUserList
})

export default rootReducer
