import { isNil,isEmpty } from 'lodash'
import { GET_TRANSACTION_SUMMARY_LIST } from '../services/getTransactionSummaryList'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { convertDate } from 'utils/dateUtils'

export const SORT_TRANSACTION_SUMMARY = 'SORT_TRANSACTION_SUMMARY'
export const SET_ERROR_MESSAGE_TRANSACTION_SUMMARY = 'SET_ERROR_MESSAGE_TRANSACTION_SUMMARY'
const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  loadingAddEdit: false,
  error: initialError,
  sortOrder: {},
  TotalJobCount:0,
  list:[]
}

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE_TRANSACTION_SUMMARY,
  message
})

export const sortTransationSummaryList = (colName, currentColOrder, dataType) => ({
  type: SORT_TRANSACTION_SUMMARY,
  colName,
  currentColOrder,
  dataType
})

export default function (state = initialState, action) {
  switch (action.type) {
    case SORT_TRANSACTION_SUMMARY: return sortTransactionSummaryInfo(state, action)
    case SET_ERROR_MESSAGE_TRANSACTION_SUMMARY : return setError(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_TRANSACTION_SUMMARY_LIST)(action)) return getTransactionSummaryList(state, action)
      if (isAsyncComplete(GET_TRANSACTION_SUMMARY_LIST)(action)) return getTransactionSummaryListResult(state, action)
      if (isAsyncFailure(GET_TRANSACTION_SUMMARY_LIST)(action)) return getTransactionSummaryListError(state, action)
      return state
    default:
      return state
  }
}

const sortTransactionSummary = (colName, colOrder, dataType) => (a, b) => {
  const aValue = isNil(a[colName]) ? '' : dataType !== 'number' ? a[colName].toLowerCase() : a[colName]
  const bValue = isNil(b[colName]) ? '' : dataType !== 'number' ? b[colName].toLowerCase() : b[colName]
  if (colOrder === 'desc') {
    if (aValue > bValue) return -1
    if (aValue < bValue) return 1
    return 0
  }
  if (aValue < bValue) return -1
  if (aValue > bValue) return 1
  return 0
}

export const sortTransactionSummaryInfo = (state, action) => {
  const currentSortOrder = action.currentColOrder === 'asc' ? 'desc' : 'asc'
  return {
    ...state,
    list: [...state.list].sort(sortTransactionSummary(action.colName, currentSortOrder, action.dataType)),
    sortOrder: { colName: action.colName, currentColOrder: currentSortOrder }
  }
}
const setError = (state, action) => ({
  ...state,
  errors: [action.message]
})
const getTransactionSummaryList = (state, action) => ({
  ...state,
  loading: true
})

const mapItem = item => ({
  ...initialState,
  CandidateName: item.candidateName,
  MobileNumber: item.mobileNumber,
  EmailId: item.emailId,
  TransactionAmount: item.transactionAmount,
  TransactionDate: convertDate(item.transactionDate, 'DD-MM-YYYY'),
  CouponCode: isEmpty(item.couponCode)? '-':item.couponCode,  
  CouponDiscount: isNil(item.couponDiscount) ? '-':Math.round(item.couponDiscount,2) +'%',
  CouponAmount: item.couponAmount >0 ? item.couponAmount: '-',
  RewardAmount :item.rewardAmount > 0 ? item.rewardAmount :'-' ,
  PaymentGatewayResponse :item.paymentGatewayResponse,
})

const getTransactionSummaryListResult = (state, action) => {
  const transactionSummary = action.response || {}
  const data = transactionSummary.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: [data[1] + ' Records Found.'],
      list: data[0].map(mapItem),
      TotalJobCount: data[1]
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: [data.errors],
      list: []
    }
  }
}

const getTransactionSummaryListError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
