import { isNil, isEmpty } from 'lodash'
import { GET_ACTIVE_CANDIDATE_LIST } from '../services/getActiveCandidateList'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { convertDate } from 'utils/dateUtils'


const CLEAR_ACTIVE_CANDIDATE_LIST = 'CLEAR_ACTIVE_CANDIDATE_LIST'
export const SORT_ACTIVE_CANDIDATE_LIST = 'SORT_ACTIVE_CANDIDATE_LIST'
export const SET_ERROR_MESSAGE_ACTIVE_CANDIDATE_LIST = 'SET_ERROR_MESSAGE_ACTIVE_CANDIDATE_LIST'

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  loadingAddEdit: false,
  error: initialError,
  sortOrder: {},
  TotalCandidateCount:0,
  list:[]
}
export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE_ACTIVE_CANDIDATE_LIST,
  message
})

export const clearActiveCandidateList = () => ({
  type: CLEAR_ACTIVE_CANDIDATE_LIST
})

export const sortActiveCandidateList = (colName, currentColOrder, dataType) => ({
  type: SORT_ACTIVE_CANDIDATE_LIST,
  colName,
  currentColOrder,
  dataType
})

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_ACTIVE_CANDIDATE_LIST: return clearActiveCandidateInfoList(state, action)
    case SORT_ACTIVE_CANDIDATE_LIST: return sortActiveCandidateListInfo(state, action)
    case SET_ERROR_MESSAGE_ACTIVE_CANDIDATE_LIST : return setError(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_ACTIVE_CANDIDATE_LIST)(action)) return getActiveCandidateList(state, action)
      if (isAsyncComplete(GET_ACTIVE_CANDIDATE_LIST)(action)) return getActiveCandidateListResult(state, action)
      if (isAsyncFailure(GET_ACTIVE_CANDIDATE_LIST)(action)) return getActiveCandidateListError(state, action)
      return state
    default:
      return state
  }
}

const sortActiveCandidate = (colName, colOrder, dataType) => (a, b) => {
  const aValue = isNil(a[colName]) ? '' : dataType !== 'number' ? a[colName].toLowerCase() : a[colName]
  const bValue = isNil(b[colName]) ? '' : dataType !== 'number' ? b[colName].toLowerCase() : b[colName]
  if (colOrder === 'desc') {
    if (aValue > bValue) return -1
    if (aValue < bValue) return 1
    return 0
  }
  if (aValue < bValue) return -1
  if (aValue > bValue) return 1
  return 0
}
export const sortActiveCandidateListInfo = (state, action) => {
  const currentSortOrder = action.currentColOrder === 'asc' ? 'desc' : 'asc'
  return {
    ...state,
    list: [...state.list].sort(sortActiveCandidate(action.colName, currentSortOrder, action.dataType)),
    sortOrder: { colName: action.colName, currentColOrder: currentSortOrder }
  }
}
const setError = (state, action) => ({
  ...state,
  errors: [action.message]
})
const getActiveCandidateList = (state, action) => ({
  ...state,
  loading: true
})

const clearActiveCandidateInfoList = (state, action) => ({
  ...initialState
})

const mapItem = item => ({
  ...initialState,
  FirstName: item.firstName,
  LastName: item.lastName,
  UserName: item.userName,
  UserLogin: item.userLogin,
  UserCreatedDate: convertDate(item.userCreatedDate, 'DD-MM-YYYY'),
  MobileNumber: item.mobileNumber,  
  AlternateMobileNumber: item.alternateMobileNumber,
  RegisterViaName: item.registerViaName ,
  LocationName :item.locationName ,
})

const getActiveCandidateListResult = (state, action) => {
  const ActiveCandidate = action.response || {}
  const data = ActiveCandidate.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: [data[1] + ' Records Found.'],
      list: data[0].map(mapItem),
      TotalCandidateCount: data[1]
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: ActiveCandidate.errors,
      list: []
    }
  }
}

const getActiveCandidateListError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})