import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const CHANGE_USER_EMAIL = 'CHANGE_USER_EMAIL'

const url = BASE_API_URL + `/v1/jobtravia/user/changeemail`
export const service = async (data) => {
  const options = {
    ...getDefaultOptions(),
    method: 'PATCH',
    body: JSON.stringify(data)
  }
  const response = await window.fetch(url, options)
  const changeEmail = await deserializeJsonResponse(response)
  throwExceptionErrors(changeEmail)
  return changeEmail
}

const mock = request => Promise.resolve({
  id: 1,
  data: [true]
})

register(CHANGE_USER_EMAIL, service, mock)
