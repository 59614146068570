import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const GET_PREFERED_ROLE = 'GET_PREFERED_ROLE'

export const service = async (id) => {
  const url = BASE_API_URL + `/v1/jobtravia/role/getrolelist/` + id
  const options = {
    ...getDefaultOptions(),
    method: 'GET'
  }
  const response = await window.fetch(url, options)
  const role = await deserializeJsonResponse(response)
  throwExceptionErrors(role)
  return role
}

const mock = request => Promise.resolve({
  id: 1,
  data: [
    { name: 'Role1', id: 1 },
    { name: 'Role2', id: 2 },
    { name: 'Role3', id: 3 },
    { name: 'Role4', id: 4 },
    { name: 'Role5', id: 5 }]
})

register(GET_PREFERED_ROLE, service, mock)
