const SET_CANDIDATE_TYPE = 'SET_CANDIDATE_TYPE'

export const setCandidateType = (value) => ({
  type: SET_CANDIDATE_TYPE,
  value
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  EmailId: '',
  CandidateType: 3
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CANDIDATE_TYPE: return setCandidateTypeInfo(state, action)
    default: return state
  }
}

const setCandidateTypeInfo = (state, action) => ({
  ...state,
  CandidateType: action.value
})
