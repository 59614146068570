import { INVITE_FRIEND_BY_EMAIL } from '../services/inviteFriendByEmail'
import { INVITE_FRIEND_BY_MOBILE } from '../services/inviteFriendByMobile'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'

export const SET_ERROR_MESSAGE_FOR_EMAIL = 'SET_ERROR_MESSAGE_FOR_EMAIL'
export const SET_ERROR_MESSAGE_FOR_MOBILE = 'SET_ERROR_MESSAGE_FOR_MOBILE'
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE'
export const CLEAR_DATA = 'CLEAR_DATA'

export const setErrorMessageForEmail = (message) => ({
  type: SET_ERROR_MESSAGE_FOR_EMAIL,
  message
})

export const setErrorMessageForMobile = (message) => ({
  type: SET_ERROR_MESSAGE_FOR_MOBILE,
  message
})

export const clearErrorMessage = (message) => ({
  type: CLEAR_ERROR_MESSAGE
})

export const clearData = (message) => ({
  type: CLEAR_DATA
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  emailErrors: initialError,
  mobileErrors: initialError,
  IsEmailSent: false,
  IsMessageSent: false,
  hasWrongEmail: false,
  hasWrongNumbers: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ERROR_MESSAGE_FOR_EMAIL: return setErrorForEmail(state, action)
    case SET_ERROR_MESSAGE_FOR_MOBILE: return setErrorForMobile(state, action)
    case CLEAR_ERROR_MESSAGE: return clearError(state, action)
    case CLEAR_DATA: return clearInfo(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(INVITE_FRIEND_BY_EMAIL)(action)) return inviteByEmail(state, action)
      if (isAsyncComplete(INVITE_FRIEND_BY_EMAIL)(action)) return inviteByEmailResult(state, action)
      if (isAsyncFailure(INVITE_FRIEND_BY_EMAIL)(action)) return inviteByEmailError(state, action)

      if (isAsyncOperation(INVITE_FRIEND_BY_MOBILE)(action)) return inviteByMobile(state, action)
      if (isAsyncComplete(INVITE_FRIEND_BY_MOBILE)(action)) return inviteByMobileResult(state, action)
      if (isAsyncFailure(INVITE_FRIEND_BY_MOBILE)(action)) return inviteByMobileError(state, action)
      return state
    default: return state
  }
}

const setErrorForEmail = (state, action) => ({
  ...state,
  emailErrors: [action.message]
})

const setErrorForMobile = (state, action) => ({
  ...state,
  mobileErrors: [action.message]
})

const clearError = (state, action) => ({
  ...state,
  emailErrors: [],
  mobileErrors: []
})

const clearInfo = (state, action) => ({
  ...state,
  IsEmailSent: false,
  IsMessageSent: false,
  hasWrongEmail: false,
  hasWrongNumbers: false
})

const inviteByEmail = (state, action) => ({
  ...initialState,
  loading: true
})

const inviteByEmailResult = (state, action) => {
  const emailSent = action.response || {}
  const data = emailSent.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      emailErrors: ['Email sent successfully.'],
      UserId: data[0].userId,
      IsEmailSent: true,
      hasWrongEmail: false
    }
  } else {
    return {
      ...state,
      loading: false,
      emailErrors: emailSent.errors,
      IsEmailSent: false,
      hasWrongEmail: true
    }
  }
}

const inviteByEmailError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const inviteByMobile = (state, action) => ({
  ...initialState,
  loading: true
})

const inviteByMobileResult = (state, action) => {
  const messageSent = action.response || {}
  const data = messageSent.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      mobileErrors: ['Messages sent successfully.'],
      IsMessageSent: true,
      hasWrongNumbers: false
    }
  } else {
    return {
      ...state,
      loading: false,
      mobileErrors: messageSent.errors,
      IsMessageSent: false,
      hasWrongNumbers: true
    }
  }
}

const inviteByMobileError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
