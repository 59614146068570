import { UPDATE_PERSONAL_DETAILS } from '../services/updatePersonalDetails'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'

export const LANGUAGE_KNOWN = 'LANGUAGE_KNOWN'
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE'

export const languageKnown = (list) => ({
  type: LANGUAGE_KNOWN,
  list
})

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE,
  message
})

export const clearErrorMessage = (message) => ({
  type: CLEAR_ERROR_MESSAGE
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  UserId: 0,
  IsPersonalDetailsUpdated: false,
  LanguageKnownList: {}
}

export const initialLanguageKnownList = {
  LanguageName: '',
  LanguageRead: false,
  LanguageWrite: false,
  LanguageSpeak: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LANGUAGE_KNOWN: return languageKnownInfo(state, action)
    case SET_ERROR_MESSAGE: return setError(state, action)
    case CLEAR_ERROR_MESSAGE: return clearError(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(UPDATE_PERSONAL_DETAILS)(action)) return updatePersonalDetails(state, action)
      if (isAsyncComplete(UPDATE_PERSONAL_DETAILS)(action)) return updatePersonalDetailsResult(state, action)
      if (isAsyncFailure(UPDATE_PERSONAL_DETAILS)(action)) return updatePersonalDetailsError(state, action)
      return state
    default: return state
  }
}

const mapItem = item => ({
  ...initialLanguageKnownList,
  LanguageName: '',
  LanguageRead: false,
  LanguageWrite: false,
  LanguageSpeak: false
})

const setError = (state, action) => ({
  ...state,
  errors: [action.message]
})

const clearError = (state, action) => ({
  ...state,
  errors: [],
  IsPersonalDetailsUpdated: false
})

const languageKnownInfo = (state, action) => ({
  ...state,
  errors: initialError,
  LanguageKnownList: action.list.map(mapItem)
})

const updatePersonalDetails = (state, action) => ({
  ...initialState,
  loading: true
})

const updatePersonalDetailsResult = (state, action) => {
  const updatePersonalDetails = action.response || {}
  const data = updatePersonalDetails.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      IsPersonalDetailsUpdated: true,
      UserId: data[0].userId
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: updatePersonalDetails.errors,
      IsPersonalDetailsUpdated: false
    }
  }
}

const updatePersonalDetailsError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
