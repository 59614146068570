import { GET_FEATURE_SERVICE } from '../services/getFeatureData'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialFeature = {
  featureId: 0,
  featureName: ''
}

const initialState = {
  loading: false,
  errors: initialError,
  list: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_FEATURE_SERVICE)(action)) return getFeature(state, action)
      if (isAsyncComplete(GET_FEATURE_SERVICE)(action)) return getFeatureResult(state, action)
      if (isAsyncFailure(GET_FEATURE_SERVICE)(action)) return getFeatureError(state, action)
      return state
    default: return state
  }
}

const getFeature = (state, action) => ({
  ...state,
  loading: true
})

const mapItem = item => ({
  ...initialFeature,
  featureId: item.featureId,
  featureName: item.featureName
})

const getFeatureResult = (state, action) => {
  const features = action.response
  return {
    ...state,
    loading: false,
    errors: initialError,
    list: features.map(mapItem)
  }
}

const getFeatureError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
