import React from 'react'
import './index.scss'
import Media from 'react-media'
import MobileFooter from './MobileFooter'
import DesktopFooter from './DesktopFooter'

const Footer = props =>
  <Media query='(min-width:767px)'>
    {matches =>
      matches ? <DesktopFooter {...props} /> : <MobileFooter {...props} />
    }
  </Media>

export default Footer
