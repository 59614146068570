import { CHANGE_COMPANY_USER_EMAIL } from '../services/changeCompanyEmail'
import { OTP_FOR_EMAIL_CHANGE } from '../services/sendOtp'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isEmpty, isNil } from 'lodash'

const CLEAR_EMAIL_CHANGE = 'CLEAR_EMAIL_CHANGE'
const SET_FUNCTION_MODE = 'SET_FUNCTION_MODE'
export const SET_CHANGE_EMAIL_ERROR_MESSAGE = 'SET_CHANGE_EMAIL_ERROR_MESSAGE'

export const clearEmailChanged = () => ({
  type: CLEAR_EMAIL_CHANGE
})

export const setFunctionMode = (value) => ({
  type: SET_FUNCTION_MODE,
  value
})

export const setChangeEmailErrorMessage = (message) => ({
  type: SET_CHANGE_EMAIL_ERROR_MESSAGE,
  message
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  EmailId: '',
  CandidateId: 0,
  OneTimePassword: '',
  CurrentEmailId: '',
  ConfirmEmailId: '',
  MobileNumber: '',
  AlternateMobileNumber: '',
  CandidateStatus: 1,
  FirstName: '',
  LastName: '',
  FullName: '',
  UserPassword: '',
  UserId: 0,
  ImageUrl: '',
  VideoUrl: '',
  LocationId: 0,
  LocationName: '',
  LanguageSupport: '',
  DateOfBirth: new Date(),
  Gender: '',
  MaritalStatus: '',
  IsHandicaped: '',
  IsEmailChanged: false,
  IsOtpGenerated: false,
  FunctionMode: '',
  CandidateType: 0,
  LanguageKnownList: [],
  CertificationDetailsList: [],
  EducationDetailsList: [],
  ProjectDetails: {},
  VideoBase64String: '',
  ImageBase64String: '',
  FileBase64String: '',
  WorkExperienceList: [],
  EmploymentId: 0,
  CompanyName: '',
  Designation: '',
  EmploymentDurationFrom: new Date(),
  EmploymentDurationTo: new Date(),
  EmploymentDurationToTillDate: false,
  Salary: 0,
  NoticePeriodId: 0,
  PreviousExperienceList: [],
  ResumeBase64String: '',
  ResumeUrl: '',
  ResumeSize: 0,
  RewardPoints: 0,
  SubscriptionPlanId: 0,
  SubscriptionAmount: 0,
  SubscriptionDays: 0,
  UserRole: 0,
  CompanyId: 0,
  JobPostCompanyName: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_EMAIL_CHANGE: return clearEmailChangedInfo(state, action)
    case SET_FUNCTION_MODE: return setFunctionModeInfo(state, action)
    case SET_CHANGE_EMAIL_ERROR_MESSAGE: return setError(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(OTP_FOR_EMAIL_CHANGE)(action)) return sendOtp(state, action)
      if (isAsyncComplete(OTP_FOR_EMAIL_CHANGE)(action)) return sendOtpResult(state, action)
      if (isAsyncFailure(OTP_FOR_EMAIL_CHANGE)(action)) return sendOtpError(state, action)

      if (isAsyncOperation(CHANGE_COMPANY_USER_EMAIL)(action)) return changeEmail(state, action)
      if (isAsyncComplete(CHANGE_COMPANY_USER_EMAIL)(action)) return changeEmailResult(state, action)
      if (isAsyncFailure(CHANGE_COMPANY_USER_EMAIL)(action)) return changeEmailError(state, action)
      return state
    default: return state
  }
}

const clearEmailChangedInfo = (state, action) => ({
  ...state,
  errors: [],
  IsEmailChanged: false,
  IsOtpGenerated: false,
  CandidateId: 0,
  CurrentEmailId: '',
  LanguageKnownList: [],
  CertificationDetailsList: [],
  EducationDetailsList: [],
  CandidateType: 0,
  FunctionMode: '',
  ProjectDetails: {},
  LocationId: 0,
  LocationName: ''
})

const setFunctionModeInfo = (state, action) => ({
  ...state,
  FunctionMode: action
})

const setError = (state, action) => ({
  ...state,
  errors: [action.message]
})

const sendOtp = (state, action) => ({
  ...state,
  loading: true
})

const sendOtpResult = (state, action) => {
  const otpSent = action.response || {}
  const data = otpSent.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: ['OTP has been Sent.'],
      IsOtpGenerated: true
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: !isNil(otpSent.MobileNumber) && !isEmpty(otpSent.MobileNumber) ? [otpSent.MobileNumber[0]] : ['Something went wrong.'],
      IsOtpGenerated: false
    }
  }
}

const sendOtpError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const changeEmail = (state, action) => ({
  ...state,
  loading: true
})

const changeEmailResult = (state, action) => {
  const changeEmail = action.response || {}
  const data = changeEmail.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      IsEmailChanged: true,
      errors: ['']
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: changeEmail.errors,
      IsEmailChanged: false
    }
  }
}

const changeEmailError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
