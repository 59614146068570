import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const GET_COMPANY = 'GET_COMPANY'

export const service = async (userId, baseUrl = BASE_API_URL) => {
  const url = baseUrl + `/v1/jobtravia/companyprofile/getcompanybyuserid/` + userId
  const options = {
    ...getDefaultOptions(),
    method: 'GET'
  }
  const response = await window.fetch(url, options)
  const company = await deserializeJsonResponse(response)
  throwExceptionErrors(company)
  return company
}

const mock = request => Promise.resolve({
  id: 1,
  data: [{
    UserId: 1,
    CurrentEmailId: 'test@gmail.com',
    MobileNumber: '9087654321'
  }]
})

register(GET_COMPANY, service, mock)
