import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const GET_TRANSACTION_SUMMARY_LIST = 'GET_TRANSACTION_SUMMARY_LIST'
export const service = async (data,pagenumber) => {  
  const url = BASE_API_URL + `/v1/jobtravia/candidateprofile/getTransactionSummaryList`
  const body = {...data, Pagenumber: pagenumber }
  const options = {
    ...getDefaultOptions(),
    method: 'POST',
    body: JSON.stringify(body)
  }
  const response = await window.fetch(url, options)
  const transactionSummary = await deserializeJsonResponse(response)
  throwExceptionErrors(transactionSummary)
  return transactionSummary
}

const mock = request => Promise.resolve({
  id: 1,
  data: [[
    { candidateName:'ABC',  mobileNumber: '7777777777',emailId: 'test1@gmail.com',transactionAmount:'1000',transactionDate:'12-07-2019',couponCode:'AAA',couponDiscount:'10',couponAmount:'100',rewardAmount:'100' },
    { candidateName:'PQR',  mobileNumber: '8888888888',emailId: 'test2@gmail.com',transactionAmount:'1000',transactionDate:'12-07-2019',couponCode:'AAA',couponDiscount:'10',couponAmount:'100',rewardAmount:'100' },
    { candidateName:'XYZ',  mobileNumber: '9999999999',emailId: 'test3@gmail.com',transactionAmount:'1000',transactionDate:'12-07-2019',couponCode:'AAA',couponDiscount:'10',couponAmount:'100',rewardAmount:'100' },]]
})

register(GET_TRANSACTION_SUMMARY_LIST, service, mock)
