import React from 'react'
import './index.scss'
import { CONTACT_EMAIL_ADDRESS, CONTACT_ADDRESS,LINKEDIN_URL, FACEBOOK_URL, INSTAGRAM_URL, YOUTUBE_URL, TWITTER_URL, TERMS_AND_CONDITION, PRIVACY_POLICY } from './constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faLinkedinIn, faInstagram, faFacebookF, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'bizsense-components'
const DesktopFooter = props =>
  <div id='footer-container-id' className='footer-container'>
    <div id='footer-component-id' className='footer-component'>
      <div id='contact-id' className='contact'>
        <div id='address-id' className='address'>
          <FontAwesomeIcon icon={faMapMarkerAlt} className='fa-icon' />{ CONTACT_ADDRESS }
        </div>
        <div id='email-address-id' className='email-address'>
          <div id='contact-email-address-id' className='contact-email-address'>
            <FontAwesomeIcon icon={faEnvelope} className='fa-icon' />{CONTACT_EMAIL_ADDRESS}
          </div>
          <div id='terms-and-condition-id' className='terms-and-condition'>           
            <Link className='link-color' onClick={props.onTermsAndConditionClick}>{TERMS_AND_CONDITION}</Link>               
          </div>
          <div id='privacy-policy-id' className='privacy-policy'>
          <Link className='link-color' onClick={props.onPrivacyPolicyClick}>{PRIVACY_POLICY}</Link>                      
          </div>
        </div>
      </div>
      <div id='social-media-section-id' className='social-media-section'>
        <div id='follow-us-id' className='follow-us'>
        Follow us on:
        </div>
        <div>
          <a id='linkedin-icon-id' className='link-color' href={LINKEDIN_URL} target='_blank' >
            <FontAwesomeIcon icon={faLinkedinIn} className='icons' />
          </a>
          <a id='facebook-icon-id' className='link-color' href={FACEBOOK_URL} target='_blank' >
          <FontAwesomeIcon icon={faFacebookF} className='icons' />
          </a>
          <a id='instagram-icon-id' className='link-color' href={INSTAGRAM_URL} target='_blank' >
          <FontAwesomeIcon icon={faInstagram} className='icons' />
          </a>
          <a id='youtube-icon-id' className='link-color' href={YOUTUBE_URL} target='_blank' >
          <FontAwesomeIcon icon={faYoutube} className='icons' />
          </a>
          <a id='twitter-icon-id' className='link-color' href={TWITTER_URL} target='_blank' >
          <FontAwesomeIcon icon={faTwitter} className='icons' />
          </a>
        </div>
      </div>
    </div>
  </div>

export default DesktopFooter
