import { UPDATE_COMPANY_DETAILS } from '../services/updateCompanyDetails'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE'

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE,
  message
})

export const clearErrorMessage = (message) => ({
  type: CLEAR_ERROR_MESSAGE
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  userId: 0,
  companyId: 0
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ERROR_MESSAGE: return setError(state, action)
    case CLEAR_ERROR_MESSAGE: return clearError(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(UPDATE_COMPANY_DETAILS)(action)) return updateCompanyDetails(state, action)
      if (isAsyncComplete(UPDATE_COMPANY_DETAILS)(action)) return updateCompanyDetailsResult(state, action)
      if (isAsyncFailure(UPDATE_COMPANY_DETAILS)(action)) return updateCompanyDetailsError(state, action)
      return state
    default: return state
  }
}

const setError = (state, action) => ({
  ...state,
  errors: [action.message]
})

const clearError = (state, action) => ({
  ...state,
  errors: [],
  IsCompanyDetailsUpdated: false
})

const updateCompanyDetails = (state, action) => ({
  ...initialState,
  loading: true
})

const updateCompanyDetailsResult = (state, action) => {
  const updateCompanyDetails = action.response || {}
  const data = updateCompanyDetails.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      IsCompanyDetailsUpdated: true
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: updateCompanyDetails.errors,
      IsCompanyDetailsUpdated: false
    }
  }
}

const updateCompanyDetailsError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
