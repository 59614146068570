import { REGISTER_COMPANY } from '../services/registerCompany'
import { GET_CITY } from '../../RegisterUser/services/getCity'
import { SEND_USER_OTP } from '../../RegisterUser/services/sendOtp'
import { UPDATE_CANDIDATE } from '../../RegisterUser/services/updateCandidate'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'
import RegisterCompany from 'RegisterCompany/components/component';

export const CLEAR_REGISTER_COMPANY = 'CLEAR_REGISTER_COMPANY'
export const SET_REGISTER_COMPANY_ERROR_MESSAGE = 'SET_REGISTER_COMPANY_ERROR_MESSAGE'
export const CLEAR_REGISTER_COMPANY_ERROR_MESSAGE = 'CLEAR_REGISTER_COMPANY_ERROR_MESSAGE'

export const clearRegisterCompany = () => ({
  type: CLEAR_REGISTER_COMPANY
})

export const setRegisterCompanyErrorMessage = (message) => ({
  type: SET_REGISTER_COMPANY_ERROR_MESSAGE,
  message
})

export const clearRegisterCompanyErrorMessage = (message) => ({
  type: CLEAR_REGISTER_COMPANY_ERROR_MESSAGE
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialOption = {
  label: '',
  options: []
}

const initialState = {
  loading: false,
  errors: initialError,
  IsOtpGenerated: false,
  IsUserRegisterd: false,
  UserId: 0,
  CompanyName:'',
  FirstName: '',
  LastName: '',
  Email: '',
  ContactNumber: '',
  AlternateNumber: '',
  City: 0,
  UserPsswrd: '',
  Otp: '',
  IsCandidateUpdated: false,
  CityList: [],
  Bearer: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_REGISTER_COMPANY_ERROR_MESSAGE: return setError(state, action)
    case CLEAR_REGISTER_COMPANY_ERROR_MESSAGE: return clearError(state, action)
    case CLEAR_REGISTER_COMPANY: return clearRegisterUserInfo(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_CITY)(action)) return getCity(state, action)
      if (isAsyncComplete(GET_CITY)(action)) return getCityResult(state, action)
      if (isAsyncFailure(GET_CITY)(action)) return getCityError(state, action)

      if (isAsyncOperation(REGISTER_COMPANY)(action)) return registerCompany(state, action)
      if (isAsyncComplete(REGISTER_COMPANY)(action)) return registerCompanyResult(state, action)
      if (isAsyncFailure(REGISTER_COMPANY)(action)) return registerCompanyError(state, action)

      if (isAsyncOperation(SEND_USER_OTP)(action)) return sendOtp(state, action)
      if (isAsyncComplete(SEND_USER_OTP)(action)) return sendOtpResult(state, action)
      if (isAsyncFailure(SEND_USER_OTP)(action)) return sendOtpError(state, action)

      if (isAsyncOperation(UPDATE_CANDIDATE)(action)) return updateCandidate(state, action)
      if (isAsyncComplete(UPDATE_CANDIDATE)(action)) return updateCandidateResult(state, action)
      if (isAsyncFailure(UPDATE_CANDIDATE)(action)) return updateCandidateError(state, action)
      return state
    default: return state
  }
}

const clearRegisterUserInfo = (state, action) => ({
  ...initialState
})

const setError = (state, action) => ({
  ...state,
  errors: [action.message]
})

const clearError = (state, action) => ({
  ...state,
  errors: [],
  IsCouponValidated: false,
  IsUserRegistered: false,
  IsOtpGenerated: false
})

const registerCompany = (state, action) => ({
  ...state,
  loading: true,
  isUserSaved: false
})

const registerCompanyResult = (state, action) => {
  const register = action.response || {}
  const data = register.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      UserId: data[0].userId,
      Bearer: data[1],
      IsUserRegisterd: true
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: register.errors,
      Bearer: '',
      IsUserRegisterd: false
    }
  }
}

const registerCompanyError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const getCity = (state, action) => ({
  ...state,
  loading: true
})

const mapItem = item => ({
  ...initialState,
  value: item.id,
  label: item.name
})

const mapCityItem = item => ({
  ...initialOption,
  label: item.stateName,
  options: item.locationList.map(mapItem)
})

const getCityResult = (state, action) => {
  const cityList = action.response || {}
  const data = cityList.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      CityList: data.map(mapCityItem)
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: cityList.errors,
      list: []
    }
  }
}

const getCityError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const sendOtp = (state, action) => ({
  ...state,
  loading: true
})

const sendOtpResult = (state, action) => {
  const otpSent = action.response || {}
  const data = otpSent.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: ['OTP has been Sent.'],
      IsOtpGenerated: true
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: !isNil(otpSent.MobileNumber) ? [otpSent.MobileNumber[0]] : ['Something went wrong.'],
      IsOtpGenerated: false
    }
  }
}

const sendOtpError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const updateCandidate = (state, action) => ({
  ...state,
  loading: true,
  isUserSaved: false
})

const updateCandidateResult = (state, action) => {
  const register = action.response || {}
  const data = register.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      UserId: data[0].userId,
      IsCandidateUpdated: true
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: register.errors,
      IsCandidateUpdated: false
    }
  }
}

const updateCandidateError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
