import { compose, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { actions as asyncActions } from 'async-ops'
import { withRouter } from 'react-router'
import App from './component'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => bindActionCreators({
  asyncOperationStart: asyncActions.asyncOperationStart
}, dispatch)

const lifecycleMethods = {
  componentDidMount () {
  }
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle(lifecycleMethods)
)

export default enhance(App)
