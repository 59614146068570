import { CHANGE_USER_EMAIL } from '../services/changeEmail'
import { OTP_FOR_EMAIL_CHANGE } from '../services/sendOtp'
import { GET_CANDIDATE } from '../services/getCandidate'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isEmpty, isNil } from 'lodash'

const CLEAR_EMAIL_CHANGE = 'CLEAR_EMAIL_CHANGE'
const SET_FUNCTION_MODE = 'SET_FUNCTION_MODE'
export const SET_CHANGE_EMAIL_ERROR_MESSAGE = 'SET_CHANGE_EMAIL_ERROR_MESSAGE'

export const clearEmailChanged = () => ({
  type: CLEAR_EMAIL_CHANGE
})

export const setFunctionMode = (value) => ({
  type: SET_FUNCTION_MODE,
  value
})

export const setChangeEmailErrorMessage = (message) => ({
  type: SET_CHANGE_EMAIL_ERROR_MESSAGE,
  message
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  EmailId: '',
  CandidateId: 0,
  OneTimePassword: '',
  CurrentEmailId: '',
  ConfirmEmailId: '',
  MobileNumber: '',
  AlternateMobileNumber: '',
  CandidateStatus: 1,
  FirstName: '',
  LastName: '',
  FullName: '',
  UserPassword: '',
  UserId: 0,
  ImageUrl: '',
  VideoUrl: '',
  LocationId: 0,
  LocationName: '',
  LanguageSupport: '',
  DateOfBirth: new Date(),
  Gender: '',
  MaritalStatus: '',
  IsHandicaped: '',
  IsEmailChanged: false,
  IsOtpGenerated: false,
  FunctionMode: '',
  CandidateType: 0,
  LanguageKnownList: [],
  CertificationDetailsList: [],
  EducationDetailsList: [],
  ProjectDetails: {},
  VideoBase64String: '',
  ImageBase64String: '',
  ProfileBase64String: '',
  FileBase64String: '',
  WorkExperienceList: [],
  EmploymentId: 0,
  CompanyName: '',
  Designation: '',
  YearsOfExperience: '',
  EmploymentDurationFrom: new Date(),
  EmploymentDurationTo: new Date(),
  EmploymentDurationToTillDate: false,
  Salary: 0,
  NoticePeriodId: 0,
  PreviousExperienceList: [],
  ResumeBase64String: '',
  ResumeUrl: '',
  ResumeSize: 0,
  RewardPoints: 0,
  SubscriptionPlanId: 0,
  SubscriptionAmount: 0,
  SubscriptionDays: 0,
  UserRole: 0,
  CompanyId: 0,
  JobPostCompanyName: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_EMAIL_CHANGE: return clearEmailChangedInfo(state, action)
    case SET_FUNCTION_MODE: return setFunctionModeInfo(state, action)
    case SET_CHANGE_EMAIL_ERROR_MESSAGE: return setError(state, action)
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(GET_CANDIDATE)(action)) return getCandidate(state, action)
      if (isAsyncComplete(GET_CANDIDATE)(action)) return getCandidateResult(state, action)
      if (isAsyncFailure(GET_CANDIDATE)(action)) return getCandidateError(state, action)

      if (isAsyncOperation(OTP_FOR_EMAIL_CHANGE)(action)) return sendOtp(state, action)
      if (isAsyncComplete(OTP_FOR_EMAIL_CHANGE)(action)) return sendOtpResult(state, action)
      if (isAsyncFailure(OTP_FOR_EMAIL_CHANGE)(action)) return sendOtpError(state, action)

      if (isAsyncOperation(CHANGE_USER_EMAIL)(action)) return changeEmail(state, action)
      if (isAsyncComplete(CHANGE_USER_EMAIL)(action)) return changeEmailResult(state, action)
      if (isAsyncFailure(CHANGE_USER_EMAIL)(action)) return changeEmailError(state, action)
      return state
    default: return state
  }
}

const clearEmailChangedInfo = (state, action) => ({
  ...state,
  errors: [],
  IsEmailChanged: false,
  IsOtpGenerated: false,
  CandidateId: 0,
  CurrentEmailId: '',
  LanguageKnownList: [],
  CertificationDetailsList: [],
  EducationDetailsList: [],
  CandidateType: 0,
  FunctionMode: '',
  ProjectDetails: {},
  LocationId: 0,
  LocationName: '',
  UserRole :0
})

const setFunctionModeInfo = (state, action) => ({
  ...state,
  FunctionMode: action
})

const setError = (state, action) => ({
  ...state,
  errors: [action.message]
})

const getCandidate = (state, action) => ({
  ...state,
  loading: true
})

const getCandidateResult = (state, action) => {
  const candidate = action.response || {}
  const data = candidate.data
  if (!isNil(data)) {
    const candidateProfile = data[0]
    return {
      ...state,
      loading: false,
      errors: initialError,
      IsEmailChanged: false,
      UserId: candidateProfile.userId,
      CandidateId: candidateProfile.candidateId,
      EmailId: candidateProfile.emailId,
      CurrentEmailId: candidateProfile.emailId,
      CandidateStatus: candidateProfile.candidateStatus,
      MobileNumber: candidateProfile.mobileNumber,
      AlternateMobileNumber: candidateProfile.alternateMobileNumber,
      FirstName: candidateProfile.firstName,
      LastName: candidateProfile.lastName,
      ImageUrl: candidateProfile.imageUrl,
      VideoUrl: candidateProfile.videoUrl,
      UserPassword: candidateProfile.userPassword,
      LocationId: candidateProfile.locationId,
      LocationName: candidateProfile.locationName,
      LanguageSupport: candidateProfile.languageSupport,
      Gender: candidateProfile.gender,
      DateOfBirth: candidateProfile.dateOfBirth,
      MaritalStatus: candidateProfile.maritalStatus,
      IsHandicaped: candidateProfile.isHandicaped,
      CandidateType: candidateProfile.candidateType,
      LanguageKnownList: candidateProfile.languageKnownList,
      CertificationDetailsList: candidateProfile.certificationDetailsList,
      EducationDetailsList: candidateProfile.educationDetailsList,
      ProjectDetails: candidateProfile.candidateProjectDetails,
      VideoBase64String: candidateProfile.videoBase64String,
      ImageBase64String: candidateProfile.imageBase64String,
      ProfileBase64String: candidateProfile.profileBase64String,
      FileBase64String: candidateProfile.otherBase64String,
      WorkExperienceList: candidateProfile.workExperienceList,
      EmploymentId: !isNil(candidateProfile.candidateEmploymentHistory) ? candidateProfile.candidateEmploymentHistory.employmentId : 0,
      CompanyName: !isNil(candidateProfile.candidateEmploymentHistory) ? candidateProfile.candidateEmploymentHistory.companyName : '',
      Designation: !isNil(candidateProfile.candidateEmploymentHistory) ? candidateProfile.candidateEmploymentHistory.designation : '',
      YearsOfExperience: !isNil(candidateProfile.candidateEmploymentHistory) ? candidateProfile.candidateEmploymentHistory.yearsOfExperience : '',
      EmploymentDurationFrom: !isNil(candidateProfile.candidateEmploymentHistory) ? candidateProfile.candidateEmploymentHistory.employmentDurationFrom : '',
      EmploymentDurationTo: !isNil(candidateProfile.candidateEmploymentHistory) ? candidateProfile.candidateEmploymentHistory.employmentDurationTo : '',
      EmploymentDurationToTillDate: !isNil(candidateProfile.candidateEmploymentHistory) ? candidateProfile.candidateEmploymentHistory.employmentDurationToTillDate : '',
      SalaryInLacs: !isNil(candidateProfile.candidateEmploymentHistory) ? candidateProfile.candidateEmploymentHistory.salaryInLacs : '',
      SalaryInThousands: !isNil(candidateProfile.candidateEmploymentHistory) ? candidateProfile.candidateEmploymentHistory.salaryInThousands : '',
      NoticePeriodId: !isNil(candidateProfile.candidateEmploymentHistory) ? candidateProfile.candidateEmploymentHistory.noticePeriodId : 0,
      MultimediaId: candidateProfile.resumeMultimediaId,
      PreviousExperienceList: candidateProfile.previousExperienceList,
      ResumeBase64String: candidateProfile.resumeBase64String,
      ResumeUrl: candidateProfile.resumeUrl,
      ResumeSize: candidateProfile.resumeSize,
      RewardPoints: candidateProfile.rewardPoints,
      SubscriptionPlanId: candidateProfile.subscriptionPlanId,
      SubscriptionAmount: candidateProfile.amount,
      SubscriptionDays: candidateProfile.noOfDays,
      ReferByUserId: candidateProfile.referByUserId,
      FullName: candidateProfile.firstName + ' ' + (!isNil(candidateProfile.lastName) ? candidateProfile.lastName : ''),
      UserRole: candidateProfile.userRole,
      CompanyId: candidateProfile.companyId,
      JobPostCompanyName: candidateProfile.companyName
    }
  } else {
    return {
      ...initialState
    }
  }
}

const getCandidateError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const sendOtp = (state, action) => ({
  ...state,
  loading: true
})

const sendOtpResult = (state, action) => {
  const otpSent = action.response || {}
  const data = otpSent.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: ['OTP has been Sent.'],
      IsOtpGenerated: true
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: !isNil(otpSent.MobileNumber) && !isEmpty(otpSent.MobileNumber) ? [otpSent.MobileNumber[0]] : ['Something went wrong.'],
      IsOtpGenerated: false
    }
  }
}

const sendOtpError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})

const changeEmail = (state, action) => ({
  ...state,
  loading: true
})

const changeEmailResult = (state, action) => {
  const changeEmail = action.response || {}
  const data = changeEmail.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      IsEmailChanged: true,
      errors: ['']
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: changeEmail.errors,
      IsEmailChanged: false
    }
  }
}

const changeEmailError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
