import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Input, FormFeedback } from 'reactstrap'
import { Field } from 'redux-form'

const Help = ({ touched, error, warning }) => {
  if (touched && (error || warning)) {
    return <FormFeedback invalid='true'>{error || warning}</FormFeedback>
  }
  return null
}

const isSelect = type => type === 'select'

const DefaultInputComponent = props => {
  if (!isSelect(props.type)) return <Input {...props} />
  return <Input {...props}>{props.options}</Input>
}

export const FieldDecorations = props => {
  const inputName = props.input.name
  const id = 'decoratedField_' + inputName
  const InputComponent = props.InputComponent || DefaultInputComponent
  const isCheckbox = props.type === 'checkbox'
  const { touched, error } = props.meta
  return (
    <FormGroup check={isCheckbox} className={props.className}>
      <InputComponent id={id} inputId={inputName} {...props} {...props.meta} {...props.input} error={touched ? error : null} check={isCheckbox ? 'true' : 'false'} />
      <Help {...props.meta} />
    </FormGroup>
  )
}

const DecoratedField = props => <Field validate={props.validate} key={props.name} {...props} component={FieldDecorations} />

DecoratedField.propTypes = {
  component: PropTypes.any,
  name: PropTypes.string
}

FieldDecorations.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  InputComponent: PropTypes.any
}

DefaultInputComponent.propTypes = {
  type: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

Help.propTypes = {
  touched: PropTypes.bool,
  error: PropTypes.string,
  warning: PropTypes.bool
}

export default DecoratedField
