import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const GET_APPLIED_JOB_LIST = 'GET_APPLIED_JOB_LIST'

export const service = async (userId, pageNumber) => {
  const url = BASE_API_URL + `/v1/jobtravia/jobsearch/appliedjobs/` + userId + `/` + pageNumber
  const options = {
    ...getDefaultOptions(),
    method: 'GET'
  }
  const response = await window.fetch(url, options)
  const jobpost = await deserializeJsonResponse(response)
  throwExceptionErrors(jobpost)
  return jobpost
}

const mock = request => Promise.resolve({
  id: 1,
  data: [[
    { jobId: 1, designation: 'Developer', Location: 'Nagpur' },
    { jobId: 2, designation: 'Tester', Location: 'Pune' },
    { jobId: 3, designation: 'Support', Location: 'Mumbai' }
  ]]
})

register(GET_APPLIED_JOB_LIST, service, mock)
