const SET_HEADER_TAB = 'SET_HEADER_TAB'

export const setHeaderTab = (value) => ({
  type: SET_HEADER_TAB,
  value
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  ActiveTab: 1
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_HEADER_TAB: return setHeaderTabInfo(state, action)
    default: return state
  }
}

const setHeaderTabInfo = (state, action) => ({
  ...state,
  ActiveTab: action.value
})
