import { RESETPASSWORD } from '../services/resetPassword'
import { actionTypes as asyncTypes, isAsyncOperation, isAsyncComplete, isAsyncFailure } from 'async-ops'
import { isNil } from 'lodash'

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  isUserExist: false,
  userId: 0,
  isUserLoggedOut: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case asyncTypes.OPERATION:
    case asyncTypes.COMPLETE:
    case asyncTypes.FAILURE:
      if (isAsyncOperation(RESETPASSWORD)(action)) return getUserLogin(state, action)
      if (isAsyncComplete(RESETPASSWORD)(action)) return getUserLoginResult(state, action)
      if (isAsyncFailure(RESETPASSWORD)(action)) return getUserLoginError(state, action)
      return state
    default: return state
  }
}

const getUserLogin = (state, action) => ({
  ...initialState,
  loading: true
})

const getUserLoginResult = (state, action) => {
  const login = action.response || {}
  const data = login.data
  if (!isNil(data)) {
    return {
      ...state,
      loading: false,
      errors: initialError,
      isUserExist: true
    }
  } else {
    return {
      ...state,
      loading: false,
      errors: login.errors,
      isUserExist: false
    }
  }
}

const getUserLoginError = (state, action) => ({
  ...state,
  loading: false,
  errors: action.errors
})
