const SET_COMPANY_TYPE = 'SET_COMPANY_TYPE'

export const setCompanyType = (value) => ({
  type: SET_COMPANY_TYPE,
  value
})

const initialError = {
  code: '',
  message: '',
  values: []
}

const initialState = {
  loading: false,
  errors: initialError,
  EmailId: '',
  CompanyType: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_COMPANY_TYPE: return setCompanyTypeInfo(state, action)
    default: return state
  }
}

const setCompanyTypeInfo = (state, action) => ({
  ...state,
  CompanyType: action.value
})
