import { register } from 'async-ops'
import { getDefaultOptions, throwExceptionErrors, deserializeJsonResponse } from '../../utils/fetch'
import { BASE_API_URL } from '../../constants/url'

export const GET_KEYSKILLS = 'GET_KEYSKILLS'

export const service = async () => {
  const url = BASE_API_URL + `/v1/jobtravia/keyskills`
  const options = {
    ...getDefaultOptions(),
    method: 'GET'
  }
  const response = await window.fetch(url, options)
  const keySkills = await deserializeJsonResponse(response)
  throwExceptionErrors(keySkills)
  return keySkills
}

const mock = request => Promise.resolve({
  id: 1,
  data: [
    { name: 'KeySkills1', id: 1 },
    { name: 'KeySkills2', id: 2 },
    { name: 'KeySkills3', id: 3 },
    { name: 'KeySkills4', id: 4 },
    { name: 'KeySkills5', id: 5 }]
})

register(GET_KEYSKILLS, service, mock)
